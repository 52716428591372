import ServiceCard from "../../components/organisms/ServiceCard";
import EmptyState from "./emptyState";
import LoadingState from "./loadingState";
import useServices from "../../hooks/useServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isNumeric } from "../../utils/text";
import { useEffect, useState } from "react";
import Pagination from "../../components/molecules/Pagination";
import Service from "../../models/service";
import URLS from "../../utils/urls";
import { Button } from "@blueprintjs/core";

export const TAB_ID = "services";
const urls = new URLS();

const LIMIT = 8;

const ServicesTab = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const paramPage = searchParams.get("page");
	const hasParamPage = isNumeric(paramPage);

	const [page, setPage] = useState(
		hasParamPage ? parseInt(searchParams.get("page") || "") : 0
	);
	const { services, isLoading } = useServices({ page, limit: LIMIT });
	const navigate = useNavigate();

	useEffect(() => {
		setSearchParams((prevParams) => {
			prevParams.set("page", page.toString());

			return new URLSearchParams(prevParams.toString());
		});
	}, [page]);

	const onCardClicked = (serviceId: string) => () => {
		navigate(urls.withServiceId(serviceId).update_service.path);
	};

	const hasNextPage =
		services?.items?.length === LIMIT &&
		services.total > services?.items?.length;
	const hasPrevPage = page > 0;

	const emptyDesign = "flex flex-col justify-center items-center gap-9";
	const nonEmptyDesign =
		"md:grid md:grid-cols-3 lg:grid-cols-4 md:gap-4 max-sm:flex max-sm:flex-col sm:justify-center max-sm:gap-3 w-full";
	const isEmpty =
		!isLoading && (!services?.items || services?.items?.length === 0);

	return (
		<div className="flex w-full flex-col items-center">
			<div className={isEmpty ? emptyDesign : nonEmptyDesign}>
				{isEmpty && !isLoading && (
					<div className="flex flex-col items-center justify-center">
						<EmptyState
							message="Create a service for the different services you offer.<br/> 
							For example: As a make up artist, create a service for <b>Bridal make up</b><br /> and 
							another for <b>Regular make up</b>"
						/>
						<Button onClick={() => navigate(urls.create_service.path)} intent="primary">
							Create a Service
						</Button>
					</div>
				)}
				{isLoading && <LoadingState />}
				{!isLoading &&
					!isEmpty &&
					services.items.map((service: Service) => {
						return (
							<ServiceCard
								key={service.id}
								onClick={onCardClicked(service.id)}
								description={service.description}
								duration={service.formattedDuration}
								title={service.title}
							/>
						);
					})}
			</div>
			{!isEmpty && (
				<div className="mt-10">
					<Pagination
						page={page + 1}
						hasNextPage={hasNextPage}
						hasPrevPage={hasPrevPage}
						onNextPage={() => setPage(page + 1)}
						onPrevPage={() => setPage(page - 1)}
					/>
				</div>
			)}
		</div>
	);
};

export default ServicesTab;
