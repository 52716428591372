import { DateTime, Duration, Interval } from "luxon";
import BookingType from "./bookingType";

import Services from "./services";

export type BookingState =
	| "CANCELLED_FOR_NON_PAYMENT"
	| "PAYMENT_INIT_FAILED"
	| "PAYMENT_INIT_SUCCESS"
	| "PENDING"
	| "PENDING_PAYMENT"
	| "CONFIRMED"
	| "ARCHIVED";

export default class Booking {
	id: string;
	title: string;
	startTime: DateTime;
	endTime: DateTime;
	services: Services;
	duration: Duration;
	customerName: string;
	customerEmail: string;
	customerPhone?: string;
	bookingType?: BookingType;
	hasCharge: boolean;
	state: BookingState;

	constructor(apiBooking?: any) {
		this.id = apiBooking?.id;
		this.title = apiBooking?.title;
		this.startTime = DateTime.fromISO(apiBooking?.start_time);
		this.endTime = DateTime.fromISO(apiBooking?.end_time);
		this.duration = Interval.fromDateTimes(
			this.startTime,
			this.endTime
		).toDuration();
		this.customerEmail = apiBooking?.customer_email;
		this.customerName = apiBooking?.customer_name;
		this.services = Services.fromArray(apiBooking?.services as any[], {
			business: apiBooking?.business,
		});
		this.bookingType = new BookingType(apiBooking?.booking_type);
		this.hasCharge = !!apiBooking.has_charge;
		this.state = apiBooking.state;
	}

	get formattedDate() {
		return this.startTime.toFormat("DD");
	}

	get formattedStartTime() {
		return this.startTime.toFormat("hh:mm a");
	}

	get formattedEndTime() {
		return this.endTime.toFormat("hh:mm a");
	}

	get fromattedDuration() {
		return this.duration?.rescale().toHuman({ unitDisplay: "short" });
	}
}
