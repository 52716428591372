import { useContext, useState } from "react";
import {
	FormGroup,
	InputGroup,
	TextArea,
	NumericInput,
	Intent,
	Dialog,
} from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";

import Button from "../../components/atoms/Button";
import PageHeading from "../../components/organisms/PageHeading";
import { formatDuration } from "../../utils/formatters";
import { AppToaster } from "../../toaster";
import * as api from "../../api";
import BusinessContext from "../../contexts/business";
import URLS from "../../utils/urls";
import Service from "../../models/service";
import withBusiness from "../../hoc/withBusiness";
import ShareSocial from "../../components/organisms/ShareSocial";
import SuccessDialog from "./SuccessDialog";

const DURATION_INCREMENTS = 15;
const urls = new URLS();

type CreateServiceProps = {
	service?: Service;
	update?: boolean;
};

const TITLE_LENGTH = 50;
const DESCRIPTION_LENGTH = 400;

const validationSchema = yup.object({
	title: yup.string().max(TITLE_LENGTH).required().label("Title"),
	description: yup.string().max(DESCRIPTION_LENGTH).label("Description"),
	price: yup.number().required().min(0).label("Price"),
	duration_in_minutes: yup
		.number()
		.min(15)
		.max(240)
		.required()
		.label("Duration"), // 4 hours
});

const handleFormSubmit = (cb: (service?: Service) => void, update?: boolean, id?: string) =>
	async (values: object, options: FormikHelpers<any>) => {
		options.setSubmitting(true);
		let service: Service | undefined;

		try {
			const successMessage = update
				? "Service Successfully updated"
				: "Service Successfully created";

			if (update) {
				await api.service.updateService(id || "", values);
			} else {
				service = await api.service.createService(values);
				options.resetForm();
				cb(service);
			}

			AppToaster.show({
				message: successMessage,
				intent: "success",
				icon: "tick",
			});
		} catch (e) {
			AppToaster.show({
				message: "Something went wrong",
				intent: "danger",
				icon: "issue",
			});
		}

		options.setSubmitting(false);
	};

const LeftPriceElement = () => {
	const { business } = useContext(BusinessContext);

	return (
		<div className="w-10">
			<span className="absolute mt-1 px-1">{business?.currency}</span>
		</div>
	);
};

const CopyBookingLink = (props: {
	link?: string;
	name?: string;
	description?: string;
}) => {
	const [showShareDialog, setShowShareDialog] = useState(false);
	const { link, name, description } = props;

	if (!link) {
		return null;
	}

	return (
		<>
			<Button
				onClick={() => setShowShareDialog(true)}
				text="Share Service Link"
				intent="primary"
			/>
			<Dialog
				isOpen={showShareDialog}
				onClose={() => setShowShareDialog(false)}
			>
				<ShareSocial link={link} title={name} description={description} />
			</Dialog>
		</>
	);
};

const CreateService = (props: CreateServiceProps) => {
	const [createdService, setCreatedService] = useState<null | Service>(null);
	const { business } = useContext(BusinessContext);
	const navigate = useNavigate();
	const formik = useFormik({
		validationSchema,
		initialValues: {
			title: props.service?.title || "",
			duration_in_minutes: props.service?.durationInMinutes || 0,
			description: props.service?.description || "",
			price: props.service?.price || 0,
		},
		onSubmit: handleFormSubmit(
			(service?: Service) => {
				if (service) {
					setCreatedService(service);
				}
			},
			props.update,
			props.service?.id
		),
	});

	const increaseDuration = () => {
		formik.setFieldValue(
			"duration_in_minutes",
			formik.values.duration_in_minutes + DURATION_INCREMENTS
		);
	};

	const decreaseDuration = () => {
		if (formik.values.duration_in_minutes > 0) {
			formik.setFieldValue(
				"duration_in_minutes",
				formik.values.duration_in_minutes - DURATION_INCREMENTS
			);
		}
	};

	const renderError = (error?: string) => {
		if (!error) {
			return null;
		}

		return <span className="text-red-600">{error}</span>;
	};

	const pageTitle = props.update ? "Update Service" : "Create Service";
	const breadCrumbs = props.update
		? urls.update_service.breadCrumbs
		: urls.create_service.breadCrumbs;

	const onCloseSuccessDialog = () => {
		setCreatedService(null);
	};

	const copyToClipboard = (link: string) => {
		navigator.clipboard.writeText(link);
	};

	const handleCopyLink = (link: string) => {
		copyToClipboard(link);

		AppToaster.show({ message: "Copied to clipboard", intent: Intent.PRIMARY });
	};

	const canSubmit = !formik.isSubmitting;

	return (
		<>
			<PageHeading
				title={pageTitle}
				breadCrumbs={breadCrumbs}
				renderRight={() => {
					return (
						<CopyBookingLink
							link={business?.bookingLink}
							description={`Hi Guys, good news!

You can now easily book my services on ${business?.bookingLink}.
							
The process is simple, fast and will prevent any mix up with other appointments 🤗
						`}
						/>
					);
				}}
			/>
			<div className="min-h-screen bg-slate-50">
				<div className="mt-10 flex w-full justify-center">
					<div className="container bg-white px-8 py-9 shadow-md max-sm:mx-5">
						<form onSubmit={formik.handleSubmit}>
							<FormGroup
								helperText={renderError(formik.errors.title)}
								label="Title"
								labelFor="text-input"
								labelInfo="(required)"
								className="mb-6"
								subLabel={`${formik.values.title.length} chars (${TITLE_LENGTH} chars limit)`}
							>
								<InputGroup
									required
									id="text-input"
									placeholder="A short title e.g Nail Appointment"
									onChange={formik.handleChange("title")}
									value={formik.values.title}
								/>
							</FormGroup>

							<FormGroup
								helperText={renderError(formik.errors.duration_in_minutes)}
								label="Duration in minutes"
								labelInfo="(required)"
								className="mb-6 max-w-[300px]"
							>
								<div className="flex flex-row justify-between">
									<Tooltip2
										content={"This is how long the appointment will take"}
									>
										<span className="text-medium">
											{formatDuration(formik.values.duration_in_minutes)}
										</span>
									</Tooltip2>

									<div>
										<Button
											onClick={decreaseDuration}
											className="mr-2"
											icon="minus"
											small
										/>
										<Button onClick={increaseDuration} icon="plus" small />
									</div>
								</div>
							</FormGroup>

							<FormGroup
								helperText={renderError(formik.errors.price)}
								label="Price of service"
								labelFor="numeric-input"
								className="mb-6"
							>
								<NumericInput
									required
									id="numeric-input"
									placeholder="The cost of the service"
									onValueChange={(v) => formik.setFieldValue("price", v)}
									value={formik.values.price}
									leftElement={<LeftPriceElement />}
									stepSize={200}
									majorStepSize={200}
								/>
							</FormGroup>

							<FormGroup
								helperText={renderError(formik.errors.description)}
								label="Description"
								labelFor="text-area-input"
								subLabel={`${formik.values.description.length} chars (${DESCRIPTION_LENGTH} chars limit)`}
								className="mb-6"
							>
								<TextArea
									id="text-area-input"
									placeholder="A short service description e.g You should come with your hair washed"
									onChange={formik.handleChange("description")}
									value={formik.values.description}
									className="w-[50vw]"
								/>
							</FormGroup>

							<Button
								type="submit"
								intent="primary"
								loading={formik.isSubmitting}
								disabled={!canSubmit}
							>
								{props.update ? "Update service" : "Create service"}
							</Button>
						</form>
					</div>
				</div>
			</div>
			<SuccessDialog
				isOpen={!!createdService}
				onClose={onCloseSuccessDialog}
				serviceTitle={get(createdService, "title")}
				onCopyLink={() => {
					if (business?.bookingLink) {
						handleCopyLink(business?.bookingLink);
					}
				}}
				onViewServices={() => {
					navigate(urls.dashboard.path);
				}}
			/>
		</>
	);
};

export default withBusiness()(CreateService);
