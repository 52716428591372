import { Duration } from "luxon";
import Business from "./business";

class Service {
	id: string;
	title: string;
	durationInMinutes: number;
	description: string;
	slug: string;
	bookingLink: string;
	price: number;
	business?: Business;
	duration?: Duration;

	constructor(apiService?: any, apiBusiness?: any) {
		this.id = apiService?.id;
		this.title = apiService?.title;
		this.description = apiService?.description;
		this.durationInMinutes = apiService?.duration_in_minutes;
		this.price = apiService?.price;
		this.slug = apiService?.slug;
		this.bookingLink = apiService?.booking_link;
		this.business = new Business(apiBusiness || apiService?.business);
		this.duration = Duration.fromMillis(
			apiService?.duration_in_minutes
				? parseInt(apiService?.duration_in_minutes) * 60 * 1000
				: 0
		);
	}

	static fromArray(apiServices: any[], models?: { business: Business }) {
		return apiServices.map(
			(apiService) => new Service(apiService, models?.business)
		);
	}

	get formattedDuration() {
		return this.duration?.rescale().toHuman({ unitDisplay: "short" });
	}

	get formattedPrice() {
		let formattedPrice = "";
		try {
			formattedPrice = new Intl.NumberFormat("en-NG", {
				style: "currency",
				currency: this.business?.currency,
			}).format(this.price);
		} catch(e) {
			// TODO: handle error on some devices "style must be either decimal percent or currency"
		}

		return formattedPrice;
	}
}

export default Service;
