import { Breadcrumb, BreadcrumbProps, Icon, H1 } from "@blueprintjs/core";
import { Breadcrumbs2 } from "@blueprintjs/popover2";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface PageHeadingProps {
	title?: string;
	subtitle?: string;
	breadCrumbs?: BreadcrumbProps[];
	renderRight?: () => ReactNode
	renderSubtitle?: () => ReactNode
}


const renderCurrentBreadcrumb = ({ text, ...restProps }: BreadcrumbProps) => {
	// customize rendering of last breadcrumb
	return <Breadcrumb {...restProps}>{text}</Breadcrumb>;
};


/**
 * 
 * @param breadcrumbs 
 * @returns 
 * 
 * This transformation is needed to use react-router-link to enable frontend routing
 */
const transformBreadcumbs = (breadcrumbs: BreadcrumbProps[]) : BreadcrumbProps[] => {
	return breadcrumbs.map(breadcrumb => {
		const { text, href, icon } = breadcrumb;
		const result: BreadcrumbProps = {};

		if (href) {
			result.text = (
				<Link className='bp3-breadcrumb' to={href}>
					{icon && <Icon icon={icon} />}
					{text}
				</Link>
			);
		} else {
			return breadcrumb;
		}

		return result;
	});
};

const PageHeading = (props: PageHeadingProps) => {
	const hasSubtitle = !!props.subtitle || !!props.renderSubtitle;

	return (
		<div className="mb-6">
			<div className="max-sm:flex-col flex flex-row justify-between">
				<div className="flex flex-col">
					<div>
						<H1 className={hasSubtitle ? "mb-0" : ""} >{props.title}</H1>
						{props.subtitle && (
							<span className="text-slate-500">
								{props.subtitle}
							</span>
						)}
						{props.renderSubtitle && props.renderSubtitle()}
					</div>
					{props.breadCrumbs && props.breadCrumbs.length &&
						<Breadcrumbs2
							currentBreadcrumbRenderer={renderCurrentBreadcrumb}
							items={transformBreadcumbs(props.breadCrumbs)}
						/>
					}
				</div>
				<div className="flex flex-col">
					<div>
						{props.renderRight && props.renderRight()}
					</div>
				</div>
			</div>
			<hr />
		</div>
	);
};

export default PageHeading;
