import { useMemo } from "react";
import { Menu } from "@blueprintjs/core";
import {
	ItemListRendererProps,
	ItemPredicate,
	ItemRenderer,
	ItemRendererProps,
	Suggest2,
} from "@blueprintjs/select";
import { MenuItem2 } from "@blueprintjs/popover2";
import { DateTime } from "luxon";

import "./index.scss";

export const TIME_FORMAT = "HH:mm";

type TimeInputProps = {
	defaultValue: DateTime;
	onTime?: (time: DateTime) => void;
}


const TimeInput = (props: TimeInputProps) => {
	const renderInputValue = (value: string) => value;

	const items = useMemo(() => {
		const values: string[] = [];
		let dateTime = DateTime.now();
		dateTime = dateTime.startOf("day");

		values.push(dateTime.toFormat(TIME_FORMAT));

		const minimumIntervalMinutes = 15; // 15 minutes
		const numberOfMinutesIntervalsPerDay =
			(60 * 24) / minimumIntervalMinutes - 1; // We subtract one because we don't want to get to midnight of the next day

		for (let i = 0; i < numberOfMinutesIntervalsPerDay; i++) {
			dateTime = dateTime.plus({ minutes: minimumIntervalMinutes });
			values.push(dateTime.toFormat(TIME_FORMAT));
		}

		return values;
	}, []);

	const areTimesEqual = (a: string, b: string) => a === b;

	const listRenderer = (_props: ItemListRendererProps<string>) => {
		const { items, renderItem, menuProps } = _props;

		const renderedItems = items
			.map(renderItem)
			.filter((item: any) => item != null);
		return (
			<Menu
				role="listbox"
				style={{
					marginTop: -10,
					marginLeft: -10,
				}}
				{...menuProps}
			>
				<div
					style={{
						maxHeight: 300,
						overflowY: "scroll",
					}}
				>
					{renderedItems}
				</div>
			</Menu>
		);
	};

	const filterTime: ItemPredicate<string> = (
		query: string,
		time: string,
		_index: any,
		exactMatch: boolean | undefined
	) => {
		const normalizedTitle = time.toLowerCase();
		const normalizedQuery = query.toLowerCase();

		if (exactMatch) {
			return normalizedTitle === normalizedQuery;
		} else {
			return normalizedTitle.indexOf(normalizedQuery) >= 0;
		}
	};

	const renderTime: ItemRenderer<string> = (
		time: string,
		renderProps: ItemRendererProps
	) => {
		if (!renderProps.modifiers.matchesPredicate) {
			return null;
		}
		return (
			<MenuItem2
				active={renderProps.modifiers.active}
				disabled={renderProps.modifiers.disabled}
				key={time}
				onClick={renderProps.handleClick}
				onFocus={renderProps.handleFocus}
				roleStructure="listoption"
				text={
					<p className="text-center ">
						{time}
					</p>}
			/>
		);
	};

	const handleValueChange = (time: string) => {
		if (props.onTime) {
			props.onTime(DateTime.fromFormat(time, TIME_FORMAT));
		}
	};

	return (
		<Suggest2
			className="time-input w-[5rem]"
			inputValueRenderer={renderInputValue}
			items={items}
			itemsEqual={areTimesEqual}
			itemPredicate={filterTime}
			itemRenderer={renderTime}
			itemListRenderer={listRenderer}
			defaultSelectedItem={props.defaultValue.toFormat(TIME_FORMAT)}
			noResults={
				<MenuItem2
					disabled={true}
					text="No results."
					roleStructure="listoption"
				/>
			}
			onItemSelect={handleValueChange}
		/>
	);
};

export default TimeInput;
