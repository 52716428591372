import { useContext } from "react";
import { Outlet } from "react-router-dom";

import Navbar from "../components/organisms/Navbar";
import AuthContext from "../contexts/auth";

type PageProps = {
	containerClassname?: string;
}

const Page = (props: PageProps) => {
	const { logout } = useContext(AuthContext);

	let containerClassname = "container p-8";

	if (props.containerClassname) {
		containerClassname += ` ${props.containerClassname}`;
	}

	return (
		<>
			<Navbar onLogout={logout}/>
			<div className="bg-slate-50 flex justify-center min-h-screen">
				<div className={containerClassname}>
					<Outlet />
				</div>
			</div>
		</>
	);
};

export default Page;