import { useState } from "react";
import { Button, FormGroup, Intent } from "@blueprintjs/core";

import SettingsSection from "./SettingsSection";
import { useFormik } from "formik";
import { exportSchema } from "./schemas";
import { SectionProps } from ".";
import { DateRange, DateRangeInput3 } from "@blueprintjs/datetime2";
import useExportCount from "../../hooks/useExportCount";
import { AppToaster } from "../../toaster";
import { DateTime } from "luxon";


export type ExportFormProps = {
	end_date: string;
	start_date: string;
}

const ExportsSection = ({ onSubmit }: SectionProps<ExportFormProps, any>) => {
	const [maxDate, setMaxDate] = useState(new Date());
	const { availability, mutate } = useExportCount();

	const formik = useFormik({
		validationSchema: exportSchema,
		initialValues: {
			start_date: "",
			end_date: ""
		},
		onSubmit: onSubmit({ onSuccessSubmit: () => { mutate(); } })
	});

	const validDates = ({startDate, endDate}: {startDate: Date | null, endDate: Date | null}) => {
		if (startDate && endDate) {
			if (DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), "days").days > 31) {
				AppToaster.show({
					message: "Invalid date selection",
					intent: "danger"
				});

				return false;
			}
		}

		return true;
	};

	const onChangeRange = (selectedRange: DateRange) => {
		const startDate = selectedRange[0];
		const endDate = selectedRange[1];

		if (validDates({startDate, endDate})) {
			if (startDate) {
				formik.setFieldValue("start_date", startDate.toISOString());
			}
	
			if (endDate) {
				formik.setFieldValue("end_date", endDate.toISOString());
			}	
		}
	};

	return (
		<SettingsSection title="Exports">
			<form onSubmit={formik.handleSubmit}>
				<FormGroup
					label="Export business Info"
					intent={formik.errors.start_date || formik.errors.end_date ? Intent.DANGER : Intent.NONE}
					helperText={formik.errors.start_date || formik.errors.end_date}
					subLabel="A max date range of 1 month"
				>
					<DateRangeInput3
						shortcuts={false}
						onChange={onChangeRange}
						maxDate={maxDate}
						startInputProps={{
							value: formik.values.start_date ?? "",
						}}
						endInputProps={{
							value: formik.values.end_date ?? ""
						}}
						
					/>
					<p className="font-light text-xs mt-2">
						{availability?.count} out of  {availability?.max} exports remaining
					</p>
				</FormGroup>
				<Button type="submit" loading={formik.isSubmitting} intent="primary" text="Export" />
			</form>
		</SettingsSection>
	);
};

export default ExportsSection;
