export default [
	{
		q: "How can MyBookings help businesses with their scheduling needs?",
		a: "MyBookings assist you in keeping all your appointments in one place, reducing the risk of double bookings, and missed appointments.",
	},
	{
		q: "Can a business have more than one service listed?",
		a: "Sure thing! A business can have multiple services listed. For instance, as a make-up artist, you could have one service for bridal make-up and another for regular make-up or as many services as you offer.",
	},
	{
		q: "Can I set different hours of operation for different days of the week using MyBookings?",
		a: "Yes, MyBookings allows you to set different hours of operation for different days of the week. This allows you to customize your schedule to fit the needs of your business and ensure that you are available to your customers when they need you.",
	},
	{
		q: "Can I customise the booking form to collect specific information from my customers?",
		a: "Yes, MyBookings allows you to customise the booking form to collect specific information from your customers, such as their name, email address, phone number, and any other information you require. This allows you to gather all the information you need to provide the best possible service to your customers.",
	},
	{
		q: "Can businesses receive payments from clients through MyBookings app?",
		a: "Yes, you can receive payments through MyBookings app, if you select the option to receive payments and provide your bank details. Terms and conditions apply.",
	},
];
