import Lottie from "lottie-react";

import pageLoaderAnimation from "../../../assets/lotties/meeting.json";

const PageLoader = () => {
	return (
		<div className="min-h-screen flex justify-center items-center">
			<Lottie
				animationData={pageLoaderAnimation}
				loop={true}
			/>;
		</div>
	);
};

export default PageLoader;
