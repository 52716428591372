import { ComponentType, useContext } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import CreateServicePage from "./pages/CreateServicePage";
import UpdateServicePage from "./pages/UpdateServicePage";
import DashboardPage from "./pages/DashboardPage";
import ProfilePage from "./pages/ProfilePage";
import ViewBookingPage from "./pages/ViewBookingPage";
import ErrorBoundaryPage from "./pages/ErrorBoundaryPage";
import LandingPage from "./pages/LandingPage";
import CreateBusinessPage from "./pages/CreateBusinessPage";
import AuthCallbackPage from "./pages/AuthCallback";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import Page from "./templates/Page";

import AuthContext from "./contexts/auth";
import { BusinessProvider } from "./contexts/business";
import PageLoader from "./components/organisms/PageLoader";
import URLS from "./utils/urls";
import { UserProvider } from "./contexts/user";
import withUserDeletion from "./hoc/withUserDeletion";
import withEmailVerification from "./hoc/withEmailVerification";

const urls = new URLS();

const ProtectedRoute = ({
	component,
	...args
}: {
	component: ComponentType;
}) => {
	const Component = withEmailVerification()(
		withUserDeletion()(
			withAuthenticationRequired(component, {
				onRedirecting: () => <div>Redirecting you to the login...</div>,
				...args,
			})
		)
	);

	return (
		<BusinessProvider>
			<UserProvider>
				<Component />
			</UserProvider>
		</BusinessProvider>
	);
};

const router = createBrowserRouter([
	{
		path: "/",
		element: <LandingPage />,
		errorElement: <ErrorBoundaryPage />,
	},
	{
		path: "/callback",
		element: <AuthCallbackPage />,
	},
	{
		path: "/privacy-policy",
		element: <PrivacyPolicyPage />,
	},
	{
		path: "/dashboard",
		element: <Page />,
		errorElement: <ErrorBoundaryPage />,
		children: [
			{
				path: urls.dashboard.pathDefinition,
				element: <ProtectedRoute component={DashboardPage} />,
				index: true,
			},
			{
				path: urls.view_booking.pathDefinition,
				element: <ProtectedRoute component={ViewBookingPage} />,
			},
			{
				path: urls.create_service.pathDefinition,
				element: <ProtectedRoute component={CreateServicePage} />,
			},
			{
				path: urls.create_business.pathDefinition,
				element: <ProtectedRoute component={CreateBusinessPage} />,
			},
			{
				path: urls.profile.pathDefinition,
				element: <ProtectedRoute component={ProfilePage} />,
			},
			{
				path: urls.update_service.pathDefinition,
				element: <ProtectedRoute component={UpdateServicePage} />,
			},
		],
	},
]);

const App = () => {
	const { isLoading, clientAuthLoading } = useContext(AuthContext);

	if (isLoading || clientAuthLoading) {
		return <PageLoader />;
	}

	return (
		<>
			<RouterProvider router={router} />
		</>
	);
};

export default App;
