export default class BankAccount {
	id: string;
	accountName: string;
	accountNumber: string;
	bankCode: string;
	bankName: string;
	currency: string;
	name: string;

	constructor(apiBankAccount: any) {
		this.id = apiBankAccount?.id;
		this.accountName = apiBankAccount?.account_name;
		this.accountNumber = apiBankAccount?.account_number;
		this.bankCode = apiBankAccount?.bank_code;
		this.bankName = apiBankAccount?.bank_name;
		this.currency = apiBankAccount?.currency;
		this.name = apiBankAccount?.name;
	}
}
