import useSWR from "swr";

import * as api from "../api";

const useSchedule = () => {
	const { data, error, isLoading } = useSWR("/api/schedules/", () => api.schedule.getSchedule());

	return {
		schedule: data,
		isLoading,
		isError: error
	};
};

export default useSchedule;