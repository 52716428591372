import { useContext, useState } from "react";
import { FormGroup, InputGroup, Button, Intent } from "@blueprintjs/core";
import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

import { AppToaster } from "../../toaster";
import * as api from "../../api";
import PageHeading from "../../components/organisms/PageHeading";
import BusinessSlugInput from "../../controlled-components/SlugInput";
import BusinessContext from "../../contexts/business";
import URLS, { BURL } from "../../utils/urls";
import { DIALOG_PARAM } from "../../hoc/withPageDialog";
import { PAGE_DIALOG_ID } from "../../types";
import SuccessDialog from "./SuccessDialog";
import AutocompleteInput from "../../controlled-components/AutocompleteInput";

const validationSchema = yup.object({
	name: yup.string().max(80).required(),
	url: yup.string().url().label("Business website"),
	phone: yup.string().required(),
	currency: yup.string().length(3).required(),
	whatsapp_link: yup.string().url().label("WhatsApp Link"),
	slug: yup.string().required(),
	location: yup.object({
		address: yup.string(),
		lat: yup.number(),
		lng: yup.number(),
	}),
});

const urls = new URLS();

const handleFormSubmit =
	(cb: () => void) => async (values: any, options: FormikHelpers<any>) => {
		options.setSubmitting(true);

		try {
			await api.business.createBusiness(values);

			AppToaster.show({
				message: "Business Successfully created",
				intent: "success",
				icon: "tick",
			});

			options.resetForm();

			cb();
		} catch (e) {
			AppToaster.show({
				message: "Something went wrong",
				intent: "danger",
				icon: "issue",
			});
		}

		options.setSubmitting(false);
	};

const CreateBusinessPage = () => {
	const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
	const [validatingSlug, setValidatingSlug] = useState(false);
	const [slugAvailable, setSlugAvailable] = useState(true);
	const { business, mutate } = useContext(BusinessContext);
	const navigate = useNavigate();
	const formik = useFormik({
		validationSchema,
		initialValues: {
			name: "",
			phone: "",
			currency: "NGN",
			slug: "",
			whatsapp_link: "",
			location: {}
		},
		onSubmit: handleFormSubmit(() => {
			setIsSuccessDialogOpen(true);
		}),
	});

	const onCloseSuccess = () => {
		// Refresh the business context
		mutate();

		setIsSuccessDialogOpen(false);

		const dash = urls.withOptions({ preserveSearch: true }).dashboard.pathUrl;

		setOnboardingParam(dash, "onboarding");

		navigate(dash.navigateUrl);
	};

	const setOnboardingParam = (url: BURL, value: PAGE_DIALOG_ID) => {
		url.searchParams.set(DIALOG_PARAM, value);
	};

	const renderError = (error?: string) => {
		if (!error) {
			return null;
		}

		return <span className="text-red-600">{error}</span>;
	};

	/**
	 * This page should only be shown if the user has no business.
	 * If/when we support multiple businesses, we will need to take
	 * this out
	 */
	if (business) {
		navigate(
			urls.withOptions({ preserveSearch: true }).dashboard.pathUrl.navigateUrl
		);
	}

	const slugValidation = async function (slug: string) {
		const result = await api.business.getBusinessSlugAvailability(slug);

		setSlugAvailable(!!result?.available);

		return result;
	};

	return (
		<>
			<PageHeading title="Create a Business" />
			<div className="min-h-screen bg-slate-50">
				<div className="mt-10 flex w-full justify-center">
					<div className="container bg-white px-8 py-9 shadow-md max-sm:mx-5">
						<form onSubmit={formik.handleSubmit}>
							<FormGroup
								helperText={renderError(formik.errors.name)}
								label="Business Name"
								labelFor="text-input"
								labelInfo="(required)"
								className="mb-6"
							>
								<InputGroup
									required
									id="text-input"
									placeholder="The name of your business e.g Alphabet Inc"
									onChange={formik.handleChange("name")}
									value={formik.values.name}
								/>
							</FormGroup>

							<FormGroup
								helperText={renderError(formik.errors.phone)}
								label="Business Phone number"
								labelInfo="(required)"
								labelFor="phone-text-input"
								className="mb-6"
							>
								<PhoneInput
									country="ng"
									value={formik.values.phone}
									onChange={(phone) => formik.setFieldValue("phone", phone)}
									inputClass="bp5-input"
									onlyCountries={["ng"]}
								/>
							</FormGroup>

							<FormGroup
								label="Preferred name"
								labelInfo="(required)"
								labelFor="slug-input"
								intent={formik.errors.slug ? Intent.DANGER : Intent.NONE}
								helperText={formik.errors.slug}
							>
								<BusinessSlugInput
									onError={(error) => {
										setSlugAvailable(!error);
										formik.setFieldError("slug", error as string);
									}}
									onSlug={(slug) => formik.setFieldValue("slug", slug)}
									slug={formik.values.slug}
									onValidating={setValidatingSlug}
									validationFunc={slugValidation}
								/>
							</FormGroup>

							<FormGroup
								label="WhatsApp Link"
								labelFor="whatsapp-input"
								intent={
									formik.errors.whatsapp_link ? Intent.DANGER : Intent.NONE
								}
								helperText={formik.errors.whatsapp_link}
							>
								<InputGroup
									id="whatsapp-input"
									value={formik.values.whatsapp_link}
									onChange={formik.handleChange("whatsapp_link")}
								/>
							</FormGroup>

							<FormGroup
								intent={formik.errors.location ? Intent.DANGER : Intent.NONE}
								helperText={(formik.errors.location as any)?.address}
								label="Business Address"
								labelFor="address-text-input"
								className="mb-6"
							>
								<AutocompleteInput
									onSelect={(v) => {
										formik.setFieldValue("location", v);
									}}
								/>
							</FormGroup>

							<Button
								disabled={
									formik.isSubmitting || !slugAvailable || validatingSlug
								}
								type="submit"
								text="Create business"
								intent="primary"
								loading={formik.isSubmitting}
							/>
						</form>
					</div>
				</div>
			</div>
			<SuccessDialog isOpen={isSuccessDialogOpen} onClose={onCloseSuccess} />
		</>
	);
};

export default CreateBusinessPage;
