import { useCallback, useState } from "react";
import { InputGroup, InputGroupProps, Spinner } from "@blueprintjs/core";
import { debounce } from "lodash";
import { Icon } from "@blueprintjs/core";

import { toSlug } from "../../utils/text";

type SlugInputProps = {
	/**
	 * This is the current value of the slug
	 */
	slug?: string;

	/**
	 * This is called when the slug is not available
	 * @param errorMessage The error message when slug is unavailable
	 * @returns
	 */
	onError: (errorMessage: string) => void;

	/**
	 * This is called when the input value changes. It is
	 * called with the formatted slug
	 * @param slug The new slug value
	 * @returns
	 */
	onSlug: (slug: string) => void;

	/**
	 * This is the value of the slug that will be used to
	 * compare with the new slug to check if the new slug
	 * is valid
	 */
	defaultSlug?: string;

	/**
	 * This is called before the slug is validated and after
	 * the slug is validated
	 * @param value The status of the validation. True if validating and false otherwise
	 * @returns
	 */
	onValidating?: (value: boolean) => void;

	validationFunc?: (slug: string) => Promise<{ available: boolean } | undefined>;

	required?: boolean;
};

const SlugInput = (props: SlugInputProps & InputGroupProps) => {
	const {
		defaultSlug,
		slug,
		onError,
		onSlug,
		onValidating,
		required,
		validationFunc,
		...inputProps
	} = props;
	const [validatingSlug, setValidatingSlug] = useState(false);
	const [slugAvailable, setSlugAvailable] = useState(!!defaultSlug);

	const slugVerify = useCallback(async (_slug: string) => {
		if (!_slug) {
			setSlugAvailable(false);
			setValidatingSlug(false);
			onValidating && onValidating(false);

			return;
		}

		try {
			if (validationFunc) {
				const res = await validationFunc(_slug);

				if (!res?.available && _slug !== defaultSlug) {
					setSlugAvailable(false);
					onError("Slug is not available");
				} else {
					setSlugAvailable(true);
				}
			}
		} catch (e) {
			console.log(e);
			// handle error
		} finally {
			setValidatingSlug(false);
			onValidating && onValidating(false);
		}
	}, []);

	const debouncedSlugVerify = useCallback(debounce(slugVerify, 30), []);

	const onSlugChange = async (e: any) => {
		const slug = toSlug(e.target?.value);

		setValidatingSlug(true);
		onValidating && onValidating(true);

		debouncedSlugVerify(slug);

		onSlug(slug);
	};

	return (
		<div className="flex w-full flex-row gap-3">
			<InputGroup
				id="slug-input"
				value={slug}
				onChange={onSlugChange}
				required={required}
				className={"w-[95%]"}
				{...inputProps}
			/>
			<div className="flex items-center justify-center">
				{validatingSlug && <Spinner size={20} />}
				{!validatingSlug && slugAvailable && (
					<Icon icon="tick" intent="success" />
				)}
				{!validatingSlug && !slugAvailable && (
					<Icon icon="error" intent="danger" />
				)}
			</div>
		</div>
	);
};

export default SlugInput;
