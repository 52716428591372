export default class BookingType {
	id: string;
	description: string;
	title: string;
	enabled: boolean;
	bookingLink: string;
	slug: string;

	constructor(apiBookingType: any) {
		this.id = apiBookingType?.id;
		this.description = apiBookingType?.description;	
		this.title = apiBookingType?.title;
		this.enabled = apiBookingType?.enabled;
		this.bookingLink = apiBookingType?.booking_link;
		this.slug = apiBookingType?.slug;
	}
}
