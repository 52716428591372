import BookingCard from "../../components/organisms/BookingCard";

const LoadingState = () => {
	return (
		<>
			<BookingCard loading />
			<BookingCard loading />
			<BookingCard loading />
			<BookingCard loading />
			<BookingCard loading />
			<BookingCard loading />
			<BookingCard loading />
		</>
	);
};

export default LoadingState;
