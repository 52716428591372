import { Button, Intent } from "@blueprintjs/core";
import { SyntheticEvent } from "react";
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import { AppToaster } from "../../../toaster";

export interface ShareSocialProps {
	link: string;
	title?: string;
	description?: string;
}

const ICON_SIZE = 42;

const copyToClipboard = (link: string) => {
	navigator.clipboard.writeText(link);
};

const handleCopyLink = (link: string) => (e: SyntheticEvent) => {
	e.preventDefault();
	e.stopPropagation();
	copyToClipboard(link);

	AppToaster.show({
		message:
			"\
		Your link has been copied. \
		",
		intent: Intent.PRIMARY,
		timeout: 7000,
	});
};

const ShareSocial = (props: ShareSocialProps) => {
	const { link, title, description } = props;

	return (
		<div className="flex flex-col p-3">
			<span className="font-bold text-lg">
				Share booking details on your socials
			</span>
			<div className="flex flex-row gap-3 mb-5">
				<EmailShareButton url={link} subject={title} body={description}>
					<EmailIcon size={ICON_SIZE} round />
				</EmailShareButton>

				<TwitterShareButton url={link} title={description || title}>
					<TwitterIcon size={ICON_SIZE} round />
				</TwitterShareButton>

				<WhatsappShareButton url={link} title={description || title}>
					<WhatsappIcon size={ICON_SIZE} round />
				</WhatsappShareButton>

				<FacebookShareButton url={link} quote={description || title}>
					<FacebookIcon size={ICON_SIZE} round />
				</FacebookShareButton>

				<TelegramShareButton url={link} title={description || title}>
					<TelegramIcon size={ICON_SIZE} round />
				</TelegramShareButton>

				<LinkedinShareButton url={link} title={title} summary={description}>
					<LinkedinIcon size={ICON_SIZE} round />
				</LinkedinShareButton>
			</div>

			<div className="flex flex-row justify-between items-center w-full p-3 bg-slate-900 rounded-md">
				<span className="text-white">{link}</span>
				<Button onClick={handleCopyLink(link)} text={"copy"} intent="primary" />
			</div>
		</div>
	);
};

export default ShareSocial;
