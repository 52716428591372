import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as config from "./config";
import { AuthProvider } from "./contexts/auth";

import "react-phone-input-2/lib/style.css";

Sentry.init({
	dsn: config.SENTRY_DSN,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<Auth0Provider
			domain={config.AUTH0_DOMAIN}
			clientId={config.AUTH0_CIENT_ID}
			audience={config.AUTH0_AUDIENCE}
			redirectUri={`${window.location.origin}/callback`}
			useRefreshTokens={true}
			cacheLocation="localstorage"
		>
			<AuthProvider>
				<App />
			</AuthProvider>
		</Auth0Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
