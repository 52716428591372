import useSWR from "swr";

import * as api from "../api";
import Booking from "../models/booking";


type useBookingsProps = {
	page?: number;
	limit?: number;
}

const useBookings = (props: useBookingsProps = {}) => {
	const { data, error, isLoading } = useSWR(`/api/bookings/page=${props.page}`, () => api.booking.getBookings({ params: props }));

	const items = data?.data.items.map((item: any) => new Booking(item));

	return {
		bookings: {
			items,
			total: data?.data.total
		},
		isLoading,
		isError: error
	};
};

export default useBookings;