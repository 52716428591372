import { Dialog, DialogBody, H2, Spinner } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";

import useBooking from "../../hooks/useBooking";
import withPathId from "../../hoc/withPathId";
import URLS from "../../utils/urls";
import PageHeading from "../../components/organisms/PageHeading";
import { ReactNode, useState } from "react";
import Button from "../../components/atoms/Button";
import * as api from "../../api";
import { AppToaster } from "../../toaster";

type ViewBookingProps = {
	bookingId?: string;
};

const urls = new URLS();

const SectionTitle = ({ children }: { children: ReactNode }) => {
	return (
		<h3 className="mb-2 text-lg font-semibold text-slate-500">{children}</h3>
	);
};

const ViewBooking = ({ bookingId }: ViewBookingProps) => {
	const { booking, isLoading } = useBooking(bookingId || "");
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
	const [isDeletingBooking, setIsDeletingBooking] = useState(false);
	const [isArchivingBooking, setIsArchivingBooking] = useState(false);
	const navigate = useNavigate();

	if (isLoading) {
		return <Spinner />;
	}

	if (!booking) {
		// TODO: Reeturn a better message
		return <h2>Something went wrong</h2>;
	}

	const onDeleteBooking = () => {
		setIsDeleteDialogOpen(true);
	};

	const onArchiveBooking = () => {
		setIsArchiveDialogOpen(true);
	};

	const onCloseDeleteDialog = () => {
		setIsDeleteDialogOpen(false);
	};

	const onCloseArchiveDialog = () => {
		setIsArchiveDialogOpen(false);
	};

	const archiveBooking = async () => {
		if (bookingId) {
			setIsArchivingBooking(true);

			try {
				await api.booking.archiveBooking(bookingId);

				AppToaster.show({
					message: "Booking Archived",
					intent: "success"
				});

				navigate(urls.dashboard_bookings.path);
			} catch (e) {
				AppToaster.show({
					message: "Something went wrong",
					intent: "danger",
					icon: "issue",
				});
			}

			setIsArchivingBooking(false);
		}
	};
	
	const deleteBooking = async () => {
		if (bookingId) {
			setIsDeletingBooking(true);

			try {
				await api.booking.deleteBooking(bookingId);

				AppToaster.show({
					message: "Booking deleted",
					intent: "success"
				});

				navigate(urls.dashboard_bookings.path);
			} catch (e) {
				AppToaster.show({
					message: "Something went wrong",
					intent: "danger",
					icon: "issue",
				});
			}

			setIsDeletingBooking(false);
		}
	};

	return (
		<>
			<PageHeading
				title="Booking"
				breadCrumbs={urls.view_booking.breadCrumbs}
				renderRight={() => {
					return (
						<div className="flex gap-4">
							<Button
								intent="danger"
								onClick={onArchiveBooking}
								text="Archive booking"
							/>
							{!booking.hasCharge &&
								<Button
									intent="danger"
									onClick={onDeleteBooking}
									text="Delete booking"
								/>
							}
						</div>
					);
				}}
			/>
			<H2 className="mb-5 text-lg text-bkn-blue-900">{booking.title}</H2>

			<div className="flex flex-col gap-4">
				<div className="rounded-sm bg-white p-8 shadow-md">
					<SectionTitle>Booking Info</SectionTitle>

					<div className="mt-4">
						<p className="text-base">
							<b>Time :</b> {booking.formattedStartTime} -{" "}
							{booking.formattedEndTime}
						</p>
						<p className="text-base">
							<b>Total Price:</b> {booking.services.formattedPrice}
						</p>
						<p className="text-base">
							<b>Total Duration:</b> {booking.services.formattedDuration}
						</p>
					</div>
				</div>

				<div className="rounded-sm bg-white p-8 shadow-md">
					<SectionTitle>Customer Info</SectionTitle>
					<div className="flex flex-row gap-3">
						<span className="text-base font-semibold">Customer name:</span>
						<span className="text-base">{booking.customerName}</span>
					</div>

					<div className="flex flex-row gap-3">
						<span className="text-base font-semibold">Customer Email:</span>
						<span className="text-base">{booking.customerEmail}</span>
					</div>

					<div className="flex flex-row gap-3">
						<span className="text-base font-semibold">Customer Phone:</span>
						<span className="text-base">{booking.customerPhone}</span>
					</div>
				</div>

				<div className="rounded-sm bg-white p-8 shadow-md">
					<SectionTitle>Services</SectionTitle>
					{booking.services.services.map((service) => {
						return (
							<div key={service.id} className="mb-4 flex flex-col gap-3">
								<h4 className="text-base font-bold">{service.title}</h4>
								<div className="pl-4">
									<div>
										<b>Price:</b> {service.formattedPrice}
									</div>
									<div>
										<b>Duration:</b> {service.formattedDuration}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<Dialog isOpen={isArchiveDialogOpen} onClose={onCloseArchiveDialog}>
				<DialogBody>
					<div className="flex flex-col items-center justify-center gap-8 bg-slate-50">
						<p className="text-center text-lg">
							Archiving this booking will make it unavailable for booking but can be retrieved later.
							<br />
							<br />
							Are you sure you want to archive
							<br /> this booking?
						</p>
						<div className="flex gap-4">
							<Button
								loading={isArchivingBooking}
								disabled={isArchivingBooking}
								large
								intent="primary"
								onClick={onCloseArchiveDialog}
							>
								No
							</Button>
							<Button
								loading={isArchivingBooking}
								disabled={isArchivingBooking}
								large
								intent="danger"
								onClick={archiveBooking}
							>
								Yes
							</Button>
						</div>
					</div>
				</DialogBody>
			</Dialog>
			<Dialog isOpen={isDeleteDialogOpen} onClose={onCloseDeleteDialog}>
				<DialogBody>
					<div className="flex flex-col items-center justify-center gap-8 bg-slate-50">
						<p className="text-center text-lg">
							Are you sure you want to delete
							<br /> this booking?
						</p>
						<div className="flex gap-4">
							<Button
								loading={isDeletingBooking}
								disabled={isDeletingBooking}
								large
								intent="primary"
								onClick={onCloseDeleteDialog}
							>
								No
							</Button>
							<Button
								loading={isDeletingBooking}
								disabled={isDeletingBooking}
								large
								intent="danger"
								onClick={deleteBooking}
							>
								Yes
							</Button>
						</div>
					</div>
				</DialogBody>
			</Dialog>
		</>
	);
};

export default withPathId({ pathSlugs: ["bookingId"] })(ViewBooking);
