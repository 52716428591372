import { useContext } from "react";

import { AppToaster } from "../../toaster";
import BusinessContext from "../../contexts/business";
import AuthContext from "../../contexts/auth";
import * as api from "../../api";
import PageHeading from "../../components/organisms/PageHeading";
import URLS from "../../utils/urls";

import BusinessSection from "./BusinessSection";
import PrivacySection from "./PrivacySection";
import ExportsSection, { ExportFormProps } from "./ExportsSection";
import BankSection, { BankSectionFormProps } from "./BankSection";
import { FormikHelpers } from "formik";

export interface onSubmitProps<T = any> {
	onSuccessSubmit?: () => Promise<void> | void
	params?: T
}

export type OnSubmitOptions = {
	setSubmitting: (value: boolean) => void;
	resetForm: () => void;
}; 

export type SectionProps<T, S> = {
	onSubmit: (props?: onSubmitProps<S>) => (values: T, options: FormikHelpers<T>) => Promise<void> | void
};

const urls = new URLS();

const SettingsPage = () => {
	const { business, mutate, isLoading } = useContext(BusinessContext);
	const { logout } = useContext(AuthContext);

	const handleExportSubmit = (props?: onSubmitProps) => async (values: ExportFormProps, options: FormikHelpers<ExportFormProps>) => {
		options.setSubmitting(true);

		try {
			const resp = await api.exports.exportBusiness(values.start_date as string, values.end_date as string);

			AppToaster.show({ message: resp?.message, intent: resp?.success ? "success" : "danger", icon: "tick" });
			
			options.resetForm({
				values: {
					end_date: "",
					start_date: ""
				}
			});

			// eslint-disable-next-line react/prop-types
			if (props && props.onSuccessSubmit) {
				// eslint-disable-next-line react/prop-types
				const { onSuccessSubmit } = props;
				if (onSuccessSubmit instanceof Promise) {
					await onSuccessSubmit();
				} else {
					onSuccessSubmit();
				}
			}

			mutate();
		} catch(e) {
			AppToaster.show({ message: "Something went wrong", intent: "danger", icon: "issue" });
		}

		options.setSubmitting(false);
	};

	const handleBusinessSubmit = () =>  async (values: object, options: OnSubmitOptions) => {
		options.setSubmitting(true);

		try {
			await api.business.updateMyBusiness(values);

			AppToaster.show({ message: "Saved", intent: "success", icon: "tick" });

			mutate();
		} catch(e) {
			AppToaster.show({ message: "Something went wrong", intent: "danger", icon: "issue" });
		}

		options.setSubmitting(false);
	};

	const handleBankSubmit = (submitOptions: onSubmitProps<BankSectionFormProps> | undefined) =>  async (values: Record<string, string | number>, options: OnSubmitOptions) => {
		options.setSubmitting(true);

		try {
			let message = "Bank account updated.";

			if (submitOptions?.params?.id) {
				await api.bankAccount.updateBankAccount(submitOptions.params.id, values);
			} else {
				await api.bankAccount.createBankAccount(values);
				message = "Bank account created.";
			}

			AppToaster.show({
				message,
				intent: "success",
				icon: "tick",
				timeout: 5000
			});
		} catch(e: unknown) {
			const error = e as { message: string };

			AppToaster.show({
				message: error.message,
				intent: "danger"
			});
		}

		options.setSubmitting(false);
	};

	const onDeleteAccepted = async () => {
		try {
			await api.user.scheduleDeletion();
			
			AppToaster.show({
				message: "Your account has been successfully scheduled to be deleted.",
				intent: "success",
				icon: "tick",
				timeout: 10000
			});

			setTimeout(() => {
				logout && logout();
			}, 400);
		} catch(e) {
			// TODO: handle
		}
	};

	return (
		<div className="flex flex-col gap-8">
			<PageHeading title="Profile" breadCrumbs={urls.profile.breadCrumbs} />
			<BusinessSection
				onSubmit={handleBusinessSubmit}
				business={business}
				loading={isLoading}
			/>
			{/* <BankSection
				onSubmit={handleBankSubmit}
				business={business}
				loading={isLoading}
			/> */}
			<ExportsSection onSubmit={handleExportSubmit} />
			<PrivacySection onDelete={onDeleteAccepted} />
		</div>
	);
};

export default SettingsPage;
