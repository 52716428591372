import AndroidDownload from "../../../assets/images/svgs/footer/android-download.svg";
import AppleDownload from "../../../assets/images/svgs/footer/apple-download.svg";
import FacebookIcon from "../../../assets/images/svgs/icons/facebook.svg";
import LinkedInIcon from "../../../assets/images/svgs/icons/linkedin.svg";
import TwitterIcon from "../../../assets/images/svgs/icons/twitter.svg";
import WhatsappIcon from "../../../assets/images/svgs/icons/whatsapp.svg";

const SocialSection = () => {
	const icons = [
		{
			alt: "Twitter Icon",
			icon: TwitterIcon,
			link: "https://twitter.com/mybookings_ng",
		},
		{
			alt: "LinkedIn Icon",
			icon: LinkedInIcon,
			link: "https://www.linkedin.com/company/mybookings-ng/",
		},
		{
			alt: "Facebook Icon",
			icon: FacebookIcon,
			link: "https://www.facebook.com/profile.php?id=100089766417171",
		},
		{
			alt: "WhatsApp Icon",
			icon: WhatsappIcon,
			link: "https://chat.whatsapp.com/KOfgMwEA4TJA6GVINeFksU",
		},
	];

	return (
		<div className="bg-bkn-mute flex flex-row-reverse justify-between gap-3 rounded-lg py-3 max-sm:flex-col">
			<div className="flex flex-row gap-5">
				{icons.map((icon, idx) => {
					return (
						<a href={icon.link} target="_blank" rel="noreferrer" key={idx}>
							<img className="h-6" src={icon.icon} alt={icon.alt} />
						</a>
					);
				})}
			</div>
			<p className="text-slate-400">
				Copyright &#xA9; 2023 MyBookings. All rights reserved.
			</p>
		</div>
	);
};

const AppStoreSection = () => {
	const appIcons = [
		{
			alt: "Download on apple",
			icon: AppleDownload,
			link: "https://apps.apple.com/us/app/my-bookings-app/id6449464149",
		},
		{
			alt: "Download on android",
			icon: AndroidDownload,
			link: "https://play.google.com/store/apps/details?id=com.mybookingsapp.app",
		},
	];

	return (
		<div className="flex justify-between gap-1 max-sm:flex-col">
			<span className="text-slate-400">MyBookings Nigeria</span>
			<div>
				{appIcons.map((icon, idx) => {
					return (
						<a href={icon.link} key={idx} target="_blank" rel="noreferrer">
							<img src={icon.icon} alt={icon.alt} />
						</a>
					);
				})}
			</div>
		</div>
	);
};

const LinksSection = () => {
	const links = [
		{
			title: "Email Us",
			link: "mailto:support@mybookingsapp.com",
		},
		{
			title: "Privacy Policy",
			link: "/privacy-policy",
		},
		{
			title: "Join our community on WhatsApp",
			link: "https://chat.whatsapp.com/KOfgMwEA4TJA6GVINeFksU",
		},
	];

	return (
		<div className="flex flex-row flex-wrap">
			{links.map((link, idx) => {
				const hasNext = idx < links.length - 1;
				let borderStyles = "";

				if (hasNext) {
					borderStyles += "border-r border-slate-400 ";
				}

				return (
					<div key={idx}>
						<a
							className={`border-w mr-3 pr-3 text-slate-400 underline ${borderStyles}`}
							href={link.link}
						>
							{link.title}
						</a>
					</div>
				);
			})}
		</div>
	);
};

const Footer = () => {
	//  We added pb-20 to make the footer avoid the tawk popup
	return (
		<div className="flex flex-col gap-5 bg-bkn-blue p-4 pb-20">
			<SocialSection />
			<AppStoreSection />
			<LinksSection />
		</div>
	);
};

export default Footer;
