import { Button, Dialog } from "@blueprintjs/core";
import Lottie from "lottie-react";

import successConfetti from "../../assets/lotties/success-confetti.json";

type SuccessDialogProps = {
	isOpen?: boolean;
	onClose?: () => void;
	onCopyLink?: () => void;
	onViewServices?: () => void;
	serviceTitle?: string;
};

const lottieStyle = {
	height: 280,
};

const SuccessDialog = (props: SuccessDialogProps) => {
	return (
		<Dialog
			isOpen={props.isOpen}
			onClose={props.onClose}
			className="relative mx-3 flex max-w-lg items-center justify-center"
		>
			<div className="absolute top-[-140px]">
				<Lottie
					animationData={successConfetti}
					loop={false}
					style={lottieStyle}
				/>
			</div>
			<div className="h-16"></div>
			<div className="mb-6 flex max-w-sm flex-col gap-4 px-3">
				<h2 className="text-center text-xl font-medium">Awesome</h2>
				{props.serviceTitle && <p className="text-center sm:text-base md:text-lg">{props.serviceTitle}</p>}
				<p className="text-center sm:text-base md:text-lg">
					Your service has been created successfully!
				</p>
				<Button onClick={props.onCopyLink} large intent="primary">
					Get my shareable link
				</Button>
				<div className="flex flex-row">
					<Button
						text="View Services Created"
						large
						minimal
						intent="primary"
						onClick={props.onViewServices}
					/>
					<Button
						text="Create New Service"
						large
						minimal
						intent="primary"
						onClick={props.onClose}
					/>
				</div>
			</div>
		</Dialog>
	);
};

export default SuccessDialog;
