import { createContext, ReactNode, useEffect, useState } from "react";
import useSWR from "swr";

import * as api from "../api";
import User from "../models/user";

type UserContextProps = {
	children: ReactNode;
};

type UserContextValue = {
	mutate: () => void;
	user?: User;
	error?: any;
	isLoading?: boolean;
};

const UserContext = createContext<UserContextValue>({
	mutate: () => null,
});

export const UserProvider = (props: UserContextProps) => {
	const { data, error, isLoading, mutate } = useSWR(
		"/api/my/account",
		() => api.user.getMe(),
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		}
	);
	const [value, setValue] = useState<UserContextValue>({
		isLoading,
		error,
		mutate,
	});

	useEffect(() => {
		setValue({
			user: data as User,
			isLoading,
			error,
			mutate,
		});
	}, [error, data, isLoading]);

	return (
		<UserContext.Provider value={value}>
			{props.children}
		</UserContext.Provider>
	);
};

export default UserContext;
