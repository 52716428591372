import { Card, CardProps, Classes, Elevation } from "@blueprintjs/core";

import { truncate } from "../../../utils/text";


interface ServiceCardProps {
	title: string;
	description: string;
	duration?: string;
	loading?: boolean;
	enabled?: boolean;
}

const ServiceCard = (props: ServiceCardProps & CardProps) => {
	const { description, duration, loading, title, ...cardProps } = props;

	return (
		<Card
			className="min-h-[150px] flex justify-between flex-col"
			interactive
			elevation={Elevation.TWO}
			{...cardProps}
		>
			<div>
				<h2 className={`${"font-bold text-lg mb-3"} ${loading ? Classes.SKELETON : ""}`}>
					{title}
				</h2>
				<span><b>Duration: </b>{duration}</span>
				<p className={`${"h-[120px] overflow-hidden text-ellipsis antialiased"} ${loading ? Classes.SKELETON : ""}`}>
					<b>Description: </b>{truncate(description || "", { maxLength: 150 })}
				</p>
			</div>
		</Card>
	);
};

export default ServiceCard;
