import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import BookingCard from "../../components/organisms/BookingCard";
import Pagination from "../../components/molecules/Pagination";
import useBookings from "../../hooks/useBookings";

import EmptyState from "./emptyState";
import LoadingState from "./loadingState";
import { isNumeric } from "../../utils/text";
import Service from "../../models/service";
import Booking from "../../models/booking";

const LIMIT = 8;

export const TAB_ID = "bookings";

const BookingsTab = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const paramPage = searchParams.get("page");
	const hasParamPage = isNumeric(paramPage);

	const [page, setPage] = useState(
		hasParamPage ? parseInt(searchParams.get("page") || "") : 0
	);
	const { bookings, isLoading } = useBookings({ page, limit: LIMIT });
	const navigate = useNavigate();

	useEffect(() => {
		setSearchParams((prevParams) => {
			prevParams.set("page", page.toString());

			return new URLSearchParams(prevParams.toString());
		});
	}, [page]);

	const onCardClicked = (bookingId: string) => () => {
		navigate(`/dashboard/bookings/${bookingId}`);
	};

	const hasNextPage =
		bookings?.items?.length === LIMIT &&
		bookings.total > bookings?.items?.length;
	const hasPrevPage = page > 0;

	const emptyDesign = "flex flex-col justify-center items-center gap-9";
	const nonEmptyDesign =
		"md:grid md:grid-cols-3 lg:grid-cols-4 md:gap-4 max-sm:flex max-sm:flex-col sm:justify-center max-sm:gap-3 w-full";
	const isEmpty =
		!isLoading && (!bookings?.items || bookings?.items?.length === 0);

	return (
		<div className="flex w-full flex-col items-center">
			<div className={isEmpty ? emptyDesign : nonEmptyDesign}>
				{isEmpty && !isLoading && <EmptyState message="No bookings found" />}
				{isLoading && <LoadingState />}
				{!isLoading &&
					!isEmpty &&
					bookings.items.map((booking: Booking) => {
						return (
							<BookingCard
								key={booking.id}
								booking={booking}
								title={booking.title}
								onClick={onCardClicked(booking.id)}
							/>
						);
					})}
			</div>
			{!isEmpty && (
				<div className="mt-10">
					<Pagination
						page={page + 1}
						hasNextPage={hasNextPage}
						hasPrevPage={hasPrevPage}
						onNextPage={() => setPage(page + 1)}
						onPrevPage={() => setPage(page - 1)}
					/>
				</div>
			)}
		</div>
	);
};

export default BookingsTab;
