import useSWR from "swr";

import * as api from "../api";

const useBooking = (id: string) => {
	const { data, error, isLoading } = useSWR(`/api/booking/${id}`, () => api.booking.getBooking(id));

	return {
		booking: data,
		isLoading,
		isError: error
	};
};

export default useBooking;