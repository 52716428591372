import NoData from "../../assets/images/svgs/noData.svg";

type EmptyStateProps = {
	message?: string;
};

const EmptyState = (props: EmptyStateProps) => {
	return (
		<div className="flex justify-center items-center h-[45vh] flex-col gap-10">
			<img
				className="h-[15vh]"
				src={NoData}
			/>
			<p dangerouslySetInnerHTML={{
				__html: props.message || "No data found"
			}} className="text-lg text-center" />
		</div>
	);
};

export default EmptyState;
