import { useState } from "react";
import { FormGroup, InputGroup, Intent, Spinner } from "@blueprintjs/core";
import { useFormik } from "formik";

import Button from "../../components/atoms/Button";
import Business from "../../models/business";
import BusinessSlugInput from "../../controlled-components/SlugInput";
import * as api from "../../api";

import { businessSchema } from "./schemas";
import SettingsSection from "./SettingsSection";
import { SectionProps } from ".";
import PhoneInput from "react-phone-input-2";
import AutocompleteInput from "../../controlled-components/AutocompleteInput";

type BusinessSectionProps = {
	business?: Business;
	loading?: boolean;
};

const Loading = () => {
	return (
		<div>
			<Spinner />
		</div>
	);
};

const BusinessSection = (props: SectionProps<any, any> & BusinessSectionProps) => {
	const [validatingSlug, setValidatingSlug] = useState(false);
	const [slugAvailable, setSlugAvailable] = useState(true);
	const formik = useFormik({
		validationSchema: businessSchema,
		initialValues: {
			name: props.business?.name || "",
			currency: props.business?.currency,
			url: props.business?.url,
			phone: props.business?.phone,
			slug: props.business?.slug,
			whatsapp_link: props.business?.whatsappLink,
			location: {
				address: props.business?.location.address,
				lng: props.business?.location.lng,
				lat: props.business?.location.lat,
			},
		},
		enableReinitialize: true,
		onSubmit: props.onSubmit(),
	});

	const isLoading = props.loading;

	const slugValidation = async function (slug: string) {
		const result = await api.business.getBusinessSlugAvailability(slug);

		setSlugAvailable(!!result?.available);

		return result;
	};

	return (
		<SettingsSection title="Business">
			{isLoading ? (
				<Loading />
			) : (
				<form onSubmit={formik.handleSubmit}>
					<FormGroup
						label="Business Name"
						labelInfo="(required)"
						labelFor="name-input"
						intent={formik.errors.name ? Intent.DANGER : Intent.NONE}
						helperText={formik.errors.name}
					>
						<InputGroup
							id="name-input"
							value={formik.values.name}
							onChange={formik.handleChange("name")}
							required
						/>
					</FormGroup>

					<FormGroup
						label="Business website"
						labelFor="url-input"
						intent={formik.errors.url ? Intent.DANGER : Intent.NONE}
						helperText={formik.errors.url}
					>
						<InputGroup
							id="url-input"
							value={formik.values.url}
							onChange={formik.handleChange("url")}
						/>
					</FormGroup>

					<FormGroup
						label="Whatsapp Link"
						labelFor="whatsapp-input"
						intent={formik.errors.whatsapp_link ? Intent.DANGER : Intent.NONE}
						helperText={formik.errors.whatsapp_link}
					>
						<InputGroup
							id="whatsapp-input"
							value={formik.values.whatsapp_link}
							onChange={formik.handleChange("whatsapp_link")}
						/>
					</FormGroup>

					<FormGroup
						label="Preferred name"
						labelInfo="(required)"
						labelFor="slug-input"
						intent={formik.errors.slug ? Intent.DANGER : Intent.NONE}
						helperText={formik.errors.slug}
					>
						<BusinessSlugInput
							onError={(error) => {
								if (error) {
									formik.setFieldError("slug", error as string);
								}
							}}
							onSlug={(slug) => formik.setFieldValue("slug", slug)}
							slug={formik.values.slug}
							defaultSlug={props.business?.slug}
							onValidating={setValidatingSlug}
							validationFunc={slugValidation}
						/>
					</FormGroup>

					<FormGroup
						intent={formik.errors.phone ? Intent.DANGER : Intent.NONE}
						helperText={formik.errors.phone}
						label="Business Phone number"
						labelInfo="(required)"
						labelFor="phone-text-input"
						className="mb-6"
					>
						<PhoneInput
							country="ng"
							value={formik.values.phone}
							onChange={(phone) => formik.setFieldValue("phone", phone)}
							inputClass="5-input"
							onlyCountries={["ng"]}
						/>
					</FormGroup>

					<FormGroup
						intent={formik.errors.location ? Intent.DANGER : Intent.NONE}
						helperText={formik.errors.location?.address}
						label="Business Address"
						labelFor="address-text-input"
						className="mb-6"
					>
						<AutocompleteInput
							defaultAddress={props.business?.location.address}
							onSelect={(v) => {
								formik.setFieldValue("location", v);
							}}
						/>
					</FormGroup>

					<Button
						disabled={formik.isSubmitting || !slugAvailable || validatingSlug}
						type="submit"
						text="Save"
						intent="primary"
						loading={formik.isSubmitting}
					/>
				</form>
			)}
		</SettingsSection>
	);
};

export default BusinessSection;
