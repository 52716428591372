import { ReactNode } from "react";
import { Card } from "@blueprintjs/core";

import MyBookings from "../assets/images/svgs/text-logo-black.svg";

const Section = (props: { children: ReactNode }) => {
	return <div>{props.children}</div>;
};

const Heading = (props: { children: ReactNode }) => {
	return (
		<h2 className="text-center text-lg font-semibold">{props.children}</h2>
	);
};

const PrivacyPolicyPage = () => {
	return (
		<div className="flex min-h-screen flex-col items-center bg-slate-50 py-9 px-8">
			<a href="/">
				<img src={MyBookings} alt="My Bookings" className="h-9 mb-5" />
			</a>
			<Card className="container flex flex-col gap-6">
				<h1 className="mb-3 text-center text-2xl font-bold underline">
					Privacy Policy
				</h1>

				<p>
					This privacy policy has been compiled to better serve those who are
					concerned with how their &apos;Personally Identifiable
					Information&apos; (PII) is being used online. PII, as described in US
					privacy law and information security, is information that can be used
					on its own or with other information to identify, contact, or locate a
					single person, or to identify an individual in context. Please read
					our privacy policy carefully to get a clear understanding of how we
					collect, use, protect or otherwise handle your Personally Identifiable
					Information in accordance with our website.
				</p>

				<Section>
					<Heading>
						What personal information do we collect from the people that visit
						our blog, website or app?
					</Heading>
					<p>
						When ordering or registering on our site, as appropriate, you may be
						asked to enter your name, email address or other details to help you
						with your experience.
					</p>
				</Section>

				<Section>
					<Heading>When do we collect information?</Heading>
					<p>
						We collect information from you when you register on our site or
						enter information on our site.
					</p>
				</Section>

				<Section>
					<Heading>How do we use your information?</Heading>
					<p>
						We may use the information we collect from you when you register,
						make a purchase, sign up for our newsletter, respond to a survey or
						marketing communication, surf the website, or use certain other site
						features in the following ways:
					</p>
					<ul className="ml-3 mt-2 list-disc">
						<li>
							To personalize your experience and to allow us to deliver the type
							of content and product offerings in which you are most interested.
						</li>
						<li>To improve our website in order to better serve you.</li>
						<li>
							To allow us to better service you in responding to your customer
							service requests.
						</li>
						<li>To ask for ratings and reviews of services or products.</li>
					</ul>
				</Section>

				<Section>
					<Heading>Do we use &apos;cookies&apos;?</Heading>
					<p className="mb-2">We do not use cookies for tracking purposes</p>
					<p className="mb-2">
						You can choose to have your computer warn you each time a cookie is
						being sent, or you can choose to turn off all cookies. You do this
						through your browser settings. Since browser is a little different,
						look at your browser&apos;s Help Menu to learn the correct way to
						modify your cookies.
					</p>
					<p className="mb-2">
						If you turn cookies off, Some of the features that make your site
						experience more efficient may not function properly.that make your
						site experience more efficient and may not function properly.
					</p>
				</Section>

				<Section>
					<Heading>Third-party disclosure</Heading>
					<p>
						We do not sell, trade, or otherwise transfer to outside parties your
						Personally Identifiable Information.
					</p>
				</Section>

				<Section>
					<Heading>Third-party links</Heading>
					<p>
						Occasionally, at our discretion, we may include or offer third-party
						products or services on our website. These third-party sites have
						separate and independent privacy policies. We therefore have no
						responsibility or liability for the content and activities of these
						linked sites. Nonetheless, we seek to protect the integrity of our
						site and welcome any feedback about these sites.
					</p>
				</Section>
			</Card>
		</div>
	);
};

export default PrivacyPolicyPage;
