import Business from "./business";

export type TIME_DAY = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";

type TIME_INTERVAL = {
	start: string;
	end: string;
};

type Times = {
	[key in TIME_DAY]: {
		intervals: TIME_INTERVAL[];
	} | undefined;
};

class Schedule {
	id: string;
	name: string;
	description?: string;
	business?: Business;
	times: Times;
	timeZone: string;
	default: boolean;
	allowDoubleBooking: boolean;

	constructor(apiSchedule: any) {
		this.id = apiSchedule.id;
		this.name = apiSchedule.name;
		this.business = new Business(apiSchedule.business);
		this.times = {} as Times;

		// We are doing this to ensure that no other unexpected field is added to the times object
		this.times.mon = apiSchedule.times.mon;
		this.times.tue = apiSchedule.times.tue;
		this.times.wed = apiSchedule.times.wed;
		this.times.thu = apiSchedule.times.thu;
		this.times.fri = apiSchedule.times.fri;
		this.times.sat = apiSchedule.times.sat;
		this.times.sun = apiSchedule.times.sun;

		this.timeZone = apiSchedule.time_zone;
		this.default = apiSchedule.default;
		this.allowDoubleBooking = apiSchedule.allow_double_booking ?? false;
	}
}

export default Schedule;
