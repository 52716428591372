export const formatDuration = (durationInMinutes: number) => {
	if (durationInMinutes < 60) {
		return `${durationInMinutes} minutes`;
	} else {
		const hours = Math.floor(durationInMinutes / 60);
		const minutes = durationInMinutes % 60;

		return `${hours} hours ${minutes} minutes`;
	}
};
