import { BreadcrumbProps } from "@blueprintjs/core";

type UrlProps = {
	/**
	 * This is the url that can be navigated to e.g /dashboard/services/546211fvgy5656
	 */
	path: string;

	/**
	 * This is an object that can be used to manipulate the path
	 */
	pathUrl: BURL;

	/**
	 * This is the name of the url field.
	 */
	pathName: string;

	/**
	 * This is the path definition. e.g /dashboard/services/:id
	 */
	pathDefinition: string;

	/**
	 * This is an array of BreadCrumbs that is specifically for that path
	 */
	breadCrumbs?: BreadcrumbProps[];
};

export class BURL extends URL {
	/**
	 * This returns a combination of the pathname and the search value. e.g
	 * pathname=/dashboard
	 * search=?test=value
	 *
	 * navigateUrl=/dashboard?test=value
	 */
	get navigateUrl(): string {
		return `${this.pathname}${this.search}`;
	}
}

type URLSOptions = {
	preserveSearch?: boolean;
};

class URLS {
	private base = "/dashboard";
	private bookingId?: string;
	private serviceId?: string;
	private bookingTypeId?: string;
	private preserveSearch = false;

	withBookingId(bookingId: string): URLS {
		this.bookingId = bookingId;

		return this;
	}

	withBookingTypeId(bookingTypeId: string): URLS {
		this.bookingTypeId = bookingTypeId;

		return this;
	}

	withServiceId(serviceId: string): URLS {
		this.serviceId = serviceId;

		return this;
	}

	withOptions(props: URLSOptions) {
		this.preserveSearch = !!props?.preserveSearch;

		return this;
	}

	private prefixBase(url: string) {
		return `${this.base}${url}`;
	}

	private getPathUrl(path: string): BURL {
		const bUrl = new BURL(path, window.location.origin);

		if (this.preserveSearch) {
			bUrl.search = window.location.search;
		}

		return bUrl;
	}

	get create_booking_type(): UrlProps {
		const path = this.prefixBase("/create-booking-type");
		const pathName = "Create Booking Type";
		const pathDefinition = this.prefixBase("/create-booking-type");
		const breadCrumbs: BreadcrumbProps[] = [
			{ text: this.dashboard.pathName, href: this.dashboard.path },
			{ text: pathName },
		];

		return {
			breadCrumbs,
			path,
			pathName,
			pathDefinition,
			pathUrl: this.getPathUrl(path),
		};
	}

	get create_service(): UrlProps {
		const path = this.prefixBase("/create-service");
		const pathName = "Create Service";
		const pathDefinition = this.prefixBase("/create-service");
		const breadCrumbs: BreadcrumbProps[] = [
			{ text: this.dashboard.pathName, href: this.dashboard.path },
			{ text: pathName },
		];

		return {
			breadCrumbs,
			path,
			pathName,
			pathDefinition,
			pathUrl: this.getPathUrl(path),
		};
	}

	get create_business(): UrlProps {
		const path = this.prefixBase("/create-business");
		const pathName = "Create Business";
		const pathDefinition = this.prefixBase("/create-business");
		const breadCrumbs: BreadcrumbProps[] = [
			{ text: this.dashboard.pathName, href: this.dashboard.path },
			{ text: pathName },
		];

		return {
			breadCrumbs,
			path,
			pathName,
			pathDefinition,
			pathUrl: this.getPathUrl(path),
		};
	}

	get dashboard(): UrlProps {
		const pathDefinition = this.prefixBase("");

		return {
			path: this.base,
			pathName: "Dashboard",
			pathDefinition,
			pathUrl: this.getPathUrl(this.base),
		};
	}

	get dashboard_bookings(): UrlProps {
		const pathDefinition = this.prefixBase("");

		return {
			path: `${this.base}?tab=bookings`,
			pathName: "Dashboard",
			pathDefinition,
			pathUrl: this.getPathUrl(this.base),
		};
	}

	get dashboard_services(): UrlProps {
		const pathDefinition = this.prefixBase("");

		return {
			path: `${this.base}?tab=services`,
			pathName: "Dashboard",
			pathDefinition,
			pathUrl: this.getPathUrl(this.base),
		};
	}

	get profile(): UrlProps {
		const path = this.prefixBase("/profile");
		const pathName = "Profile";
		const pathDefinition = this.prefixBase("/profile");
		const breadCrumbs: BreadcrumbProps[] = [
			{ text: this.dashboard.pathName, href: this.dashboard.path },
			{ text: pathName },
		];

		return {
			breadCrumbs,
			path,
			pathName,
			pathDefinition,
			pathUrl: this.getPathUrl(path),
		};
	}

	get view_booking(): UrlProps {
		const path = this.prefixBase(`/bookings/${this.bookingId}`);
		const pathName = "View Booking";
		const pathDefinition = this.prefixBase("/bookings/:bookingId");
		const breadCrumbs: BreadcrumbProps[] = [
			{ text: this.dashboard.pathName, href: this.dashboard.path },
			{ text: pathName },
		];

		return {
			breadCrumbs,
			path,
			pathName,
			pathDefinition,
			pathUrl: this.getPathUrl(path),
		};
	}

	get update_booking_type(): UrlProps {
		const path = this.prefixBase(`/booking-types/${this.bookingTypeId}`);
		const pathName = "Update Booking Type";
		const pathDefinition = this.prefixBase("/booking-types/:bookingTypeId");
		const breadCrumbs: BreadcrumbProps[] = [
			{ text: this.dashboard.pathName, href: this.dashboard.path },
			{ text: pathName },
		];

		return {
			breadCrumbs,
			path,
			pathName,
			pathDefinition,
			pathUrl: this.getPathUrl(path),
		};
	}

	get update_service(): UrlProps {
		const path = this.prefixBase(`/services/${this.serviceId}`);
		const pathName = "Update Service";
		const pathDefinition = this.prefixBase("/services/:serviceId");
		const breadCrumbs: BreadcrumbProps[] = [
			{ text: this.dashboard.pathName, href: this.dashboard.path },
			{ text: pathName },
		];

		return {
			breadCrumbs,
			path,
			pathName,
			pathDefinition,
			pathUrl: this.getPathUrl(path),
		};
	}
}

export const URL_REGEX =
	/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/; // eslint-disable-line

export default URLS;
