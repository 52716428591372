import { createContext, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import * as api from "../api";
import Business from "../models/business";
import URLS from "../utils/urls";

type BusinessContextProps = {
	children: ReactNode;
};

type BusinessContextValue = {
	mutate: () => void;
	business?: Business;
	isLoading?: boolean;
};

const urls = new URLS();
const BusinessContext = createContext<BusinessContextValue>({
	mutate: () => null,
});

export const BusinessProvider = (props: BusinessContextProps) => {
	const { data, error, isLoading, mutate } = useSWR(
		"/api/my/business",
		() => api.business.getMyBusiness(),
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		}
	);
	const [value, setValue] = useState<BusinessContextValue>({
		isLoading,
		mutate,
	});
	const navigate = useNavigate();

	useEffect(() => {
		setValue({
			business: data as Business,
			isLoading,
			mutate,
		});

		if (error?.data?.code === "business_not_found") {
			return navigate(urls.create_business.path);
		}
	}, [error, data]);

	return (
		<BusinessContext.Provider value={value}>
			{props.children}
		</BusinessContext.Provider>
	);
};

export default BusinessContext;
