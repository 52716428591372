export default class Bank {
	id: number;
	bankName: string;
	bankCode: string;
	slug?: string;
	currency: string;

	constructor(apiBank: any) {
		this.id = apiBank?.id;
		this.bankCode = apiBank?.code;
		this.bankName = apiBank?.name;
		this.currency = apiBank?.currency;
		this.slug = apiBank?.slug;
	}
}
