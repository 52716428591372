import { type AxiosResponse } from "axios";
import Booking from "../models/booking";
import BookingType from "../models/bookingType";
import Business from "../models/business";
import Schedule from "../models/schedule";
import Service from "../models/service";
import Client, { type ClientOptions } from "./client";
import {
	ExportBusinessCountResponse,
	ExportBusinessResponse,
	type ResendVerifyEmail,
	type UpdateScheduleBody,
} from "./types";
import User from "../models/user";
import BankAccount from "../models/bankAccount";
import Bank from "../models/bank";

class BankApi {
	client: Client;

	constructor() {
		this.client = new Client();
	}

	async getBanks() {
		const resp = await this.client.get("/banks");

		if (resp) {
			return resp.data?.map((b: any) => new Bank(b));
		}
	}

	async resolve(account_number: string, bank_code: string) {
		const resp = await this.client.get("/banks/resolve", {
			params: { account_number, bank_code },
		});

		if (resp?.data?.data) {
			return resp.data.data as { account_number: string; account_name: string };
		}
	}
}

class BankAccountApi {
	client: Client;

	constructor() {
		this.client = new Client();
	}

	async createBankAccount(body: any) {
		const resp: AxiosResponse<any, any> | void = await this.client.post(
			"/bank_accounts",
			body
		);
		if (resp && resp.data) {
			return new BankAccount(resp.data);
		}
	}

	async getBankAccounts(options?: ClientOptions) {
		const resp = await this.client.get("/bank_accounts", options);

		if (resp && resp.data) {
			return {
				...resp.data,
				items: resp.data?.items?.map((i: any) => new BankAccount(i)),
			};
		}
	}

	async getBankAccount(id: string) {
		const resp = await this.client.get(`/bank_accounts/${id}`);

		if (resp && resp.data) {
			return new BankAccount(resp.data);
		}
	}

	async updateBankAccount(id: string, body: any) {
		const resp: AxiosResponse<any, any> | void = await this.client.put(
			`/bank_accounts/${id}`,
			body
		);
		if (resp && resp.data) {
			return new BankAccount(resp.data);
		}
	}
}

class BookingApi {
	client: Client;

	constructor() {
		this.client = new Client();
	}

	async archiveBooking(id: string) {
		return await this.client.post(`/bookings/${id}/archive`);
	}

	async unarchiveBooking(id: string) {
		return await this.client.post(`/bookings/${id}/unarchive`);
	}

	async getBookings(options?: ClientOptions) {
		return await this.client.get("/bookings", options);
	}

	async getBooking(id: string, options?: ClientOptions) {
		const resp = await this.client.get(`/bookings/${id}`, options);

		if (resp && resp.data) {
			return new Booking(resp.data);
		}
	}

	async deleteBooking(id: string, options?: ClientOptions) {
		await this.client.delete(`/bookings/${id}`, options);
	}

	async makeCustomerPaymentExempt(id: string) {
		const resp = await this.client.post(`/bookings/${id}/payment_exempt`);

		if (resp?.data) {
			return resp.data as { success: string; message: string };
		}
	}

	async makeCustomerPaymentNonExempt(id: string) {
		const resp = await this.client.post(
			`/bookings/${id}/payment_non_exempt`,
			{}
		);

		if (resp?.data) {
			return resp.data as { success: string; message: string };
		}
	}
}

class BusinessApi {
	client: Client;

	constructor() {
		this.client = new Client();
	}

	async getPresignedUrl(filename: string) {
		const resp = await this.client.post("/businesses/me/logo/upload", {
			filename,
		});

		return resp?.data?.uploadUrl;
	}

	async createBusiness(body: any, options?: ClientOptions) {
		const resp = await this.client.post("/businesses", body, options);

		if (resp && resp.data) {
			return new Business(resp.data);
		}
	}

	async getMyBusiness() {
		const resp: AxiosResponse<any, any> | void = await this.client.get(
			"/businesses/me"
		);
		if (resp && resp.data) {
			return new Business(resp.data);
		}
	}

	async getBusinessSlugAvailability(slug: string) {
		const resp: AxiosResponse<any, any> | void = await this.client.get(
			`/businesses/slugs/${slug}/available`
		);
		if (resp && resp.data) {
			return resp.data as { available: boolean };
		}
	}

	async updateMyBusiness(body: any) {
		const resp: AxiosResponse<any, any> | void = await this.client.put(
			"/businesses/me",
			body
		);
		if (resp && resp.data) {
			return new Business(resp.data);
		}
	}
}

class BookingTypeApi {
	client: Client;

	constructor() {
		this.client = new Client();
	}

	async createBookingType(body: any, options?: ClientOptions) {
		const resp = await this.client.post("/booking-types", body, options);

		if (resp && resp.data) {
			return new BookingType(resp.data);
		}
	}

	async getBookingTypes(options?: ClientOptions) {
		const resp = await this.client.get("/booking-types", options);

		if (resp && resp.data) {
			return {
				...resp.data,
				items: resp.data.items?.map((i: any) => new BookingType(i)),
			};
		}
	}

	async getBookingType(id: string, options?: ClientOptions) {
		const resp = await this.client.get(`/booking-types/${id}`, options);

		if (resp && resp.data) {
			return new BookingType(resp.data);
		}
	}

	async getSlugAvailability(slug: string) {
		const resp = await this.client.get(
			`/booking-types/slugs/${slug}/available`
		);
		if (resp && resp.data) {
			return resp.data as { available: boolean };
		}
	}

	async updateBookingType(id: string, body: any, options?: ClientOptions) {
		const resp = await this.client.put(`/booking-types/${id}`, body, options);

		if (resp && resp.data) {
			return new BookingType(resp.data);
		}
	}
}

class ServiceApi {
	client: Client;

	constructor() {
		this.client = new Client();
	}

	async createService(body: any, options?: ClientOptions) {
		const resp = await this.client.post("/services", body, options);

		if (resp && resp.data) {
			return new Service(resp.data);
		}
	}

	async getServices(options?: ClientOptions) {
		const resp = await this.client.get("/services", options);

		if (resp && resp.data) {
			return {
				...resp.data,
				items: resp.data.items?.map((i: any) => new Service(i)),
			};
		}
	}

	async getService(id: string, options?: ClientOptions) {
		const resp = await this.client.get(`/services/${id}`, options);

		if (resp && resp.data) {
			return new Service(resp.data);
		}
	}

	async updateService(id: string, body: any, options?: ClientOptions) {
		const resp = await this.client.put(`/services/${id}`, body, options);

		if (resp && resp.data) {
			return new Service(resp.data);
		}
	}

	async getSlugAvailability(slug: string) {
		const resp = await this.client.get(`/services/slugs/${slug}/available`);
		if (resp && resp.data) {
			return resp.data as { available: boolean };
		}
	}

	async archiveService(id: string, options?: ClientOptions) {
		const resp = await this.client.put(`/services/${id}/archive`, {}, options);

		if (resp && resp.data) {
			return new Service(resp.data);
		}
	}

	async unarchiveService(id: string, options?: ClientOptions) {
		const resp = await this.client.put(
			`/services/${id}/unarchive`,
			{},
			options
		);

		if (resp && resp.data) {
			return new Service(resp.data);
		}
	}
}

class ScheduleApi {
	client: Client;

	constructor() {
		this.client = new Client();
	}

	async getSchedule() {
		const resp = await this.client.get("/schedules");

		if (resp && resp.data) {
			return new Schedule(resp.data.items[0]);
		}
	}

	async updateSchedule(body: UpdateScheduleBody) {
		const resp = await this.client.put("/schedules", body);

		if (resp && resp.data) {
			return new Schedule(resp.data);
		}
	}
}

class DeviceApi {
	client: Client;

	constructor() {
		this.client = new Client();
	}

	async getStatus(version?: string) {
		const resp = await this.client.get("/devices/status", {
			params: { version },
		});

		if (resp) {
			return resp.data as { message?: string; valid?: boolean; code?: string };
		}
	}

	async createDevice({
		expo_token,
		device_type,
	}: {
		expo_token: string;
		device_type: string;
	}) {
		await this.client.post("/devices", { expo_token, device_type });
	}

	async deleteDevice({ expo_token }: { expo_token: string }) {
		await this.client.delete("/devices", { body: { expo_token } });
	}
}

class UserApi {
	client: Client;

	constructor() {
		this.client = new Client();
	}

	async getMe() {
		const resp = await this.client.get("/users/me");

		if (resp && resp.data) {
			return new User(resp.data);
		}
	}

	async scheduleDeletion() {
		return await this.client.delete("/users/me");
	}

	async resendVerifyEmail(): Promise<ResendVerifyEmail | undefined> {
		const resp = await this.client.post("/users/me/resend-verify-email", {});

		if (resp && resp.data) {
			const data = resp.data;

			return {
				message: data.message,
				code: data.code,
				success: data.success,
			};
		}
	}
}

class ExportsApi {
	client: Client;

	constructor() {
		this.client = new Client();
	}

	async exportBusiness(
		startDate: string,
		endDate: string
	): Promise<ExportBusinessResponse | undefined> {
		const resp = await this.client.post("/exports/business", {
			start_time: startDate,
			end_time: endDate,
		});

		if (resp && resp.data) {
			const data = resp.data;

			return {
				message: data.message,
				code: data.code,
			};
		}
	}

	async count(): Promise<ExportBusinessCountResponse | undefined> {
		const resp = await this.client.get("/exports/count");

		if (resp && resp.data) {
			const data = resp.data;

			return {
				available: data.available,
				count: data.count,
				max: data.max,
			};
		}
	}
}

export const bank = new BankApi();
export const bankAccount = new BankAccountApi();
export const booking = new BookingApi();
export const bookingType = new BookingTypeApi();
export const business = new BusinessApi();
export const exports = new ExportsApi();
export const service = new ServiceApi();
export const schedule = new ScheduleApi();
export const device = new DeviceApi();
export const user = new UserApi();
