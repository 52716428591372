import { AnchorButton, Intent } from "@blueprintjs/core";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import NotFound from "../assets/images/svgs/errors/404.svg";
import Navbar from "../components/organisms/Navbar";

type ErrorResponse = {
	status: number;
}

const renderErrorResponse = (error: ErrorResponse) => {
	switch(error.status) {
	case 404:
		return (
			<div>
				<img className="h-[30vh] w-auto" alt="404 svg" src={NotFound} />
				<h1 className="font-bold text-3xl text-center mt-7"> Page Not Found </h1>
				<div className="w-full flex justify-center mt-7">
					<AnchorButton href="/dashboard" text="Dashboard" intent={Intent.PRIMARY} />
				</div>
			</div>
		);
	default:
		return (
			<div>
				<h1 className="font-bold text-3xl text-center"> Something went wrong </h1>
				<div className="w-full flex justify-center mt-7">
					<AnchorButton href="/dashboard" text="Dashboard" intent={Intent.PRIMARY} />
				</div>
			</div>
		);
	}
};

const renderError = (error: any) => {
	return (
		<div>
			<h1 className="font-bold text-3xl text-center mt-7"> Something went wrong </h1>
			<p className="font-bold text-base text-center mt-7"> {error?.message} </p>
			<div className="w-full flex justify-center mt-7">
				<AnchorButton href="/dashboard" text="Home" intent={Intent.PRIMARY} />
			</div>
		</div>
	);
};

const ErrorPage = () => {
	const error = useRouteError();
	

	return (
		<div className="min-h-screen flex justify-center items-center">
			<Navbar navbarProps={{ fixedToTop: true }} />
			{isRouteErrorResponse(error) ? renderErrorResponse(error) : renderError(error)}
		</div>
	);
};

export default ErrorPage;