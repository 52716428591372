/* eslint-disable react/display-name */
import { useNavigate } from "react-router";
import { useContext } from "react";

import BusinessContext from "../contexts/business";
import URLS from "../utils/urls";
import PageLoader from "../components/organisms/PageLoader";

const urls = new URLS();

const withBusiness = () => (Component: any) => (props: any) => {
	const { business, isLoading } = useContext(BusinessContext);
	const navigate = useNavigate();

	if (isLoading) {
		return <PageLoader />;
	}

	if (!business && !window.location.href.includes(urls.create_business.path)) {
		navigate(urls.withOptions({ preserveSearch: true }).create_business.pathUrl.navigateUrl);
	}

	return <Component {...props} />;
};

export default withBusiness;
