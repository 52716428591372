class LocalStorage {
	private static instance: LocalStorage;

	private constructor() {
		// Empty constructor
	}

	public static getInstance(): LocalStorage {
		if (LocalStorage.instance) {
			return LocalStorage.instance;
		}

		LocalStorage.instance = new LocalStorage();

		return LocalStorage.instance;
	}

	get(key: string) {
		return localStorage.getItem(key);
	}

	getJson(key: string): any | null {
		const val = this.get(key);

		if (val) {
			return JSON.parse(val);
		}
	}

	set(key: string, value: string) {
		return localStorage.setItem(key, value);
	}

	setJson(key: string, value: any) {
		return this.set(key, JSON.stringify(value));
	}

	remove(key: string) {
		return localStorage.removeItem(key);
	}
}

export default LocalStorage;
