import axios, { AxiosResponse } from "axios";

import { SERVER_URL } from "../config";

class ClientError extends Error {
	data: any;

	constructor(message: string) {
		super(message);
	}
}

export type ClientOptions = {
	params?: { [key: string]: any };
	body?: unknown;
	timeout?: number
};

type ErrorHandleProps = {
	response: {
		data: {
			message: string;
		};
	};
};

axios.defaults.baseURL = `${SERVER_URL}/api/v1`;

class Client {
	static hasToken = false;

	static addResponseInterceptor(
		responseHandler:
			| ((
					value: AxiosResponse<any, any>
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  ) => AxiosResponse<any, any> | Promise<AxiosResponse<any, any>>)
			| null
			| undefined
	) {
		axios.interceptors.response.use(responseHandler, (error) => error);
	}

	static addErrorInterceptor(
		errorHandler: ((error: any) => any) | null | undefined
	) {
		axios.interceptors.response.use((response) => response, errorHandler);
	}

	setBaseUrl(url: string) {
		axios.defaults.baseURL = url;
	}

	static setToken(token: string) {
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		Client.hasToken = !!token;
	}

	private errorHandler(e: ErrorHandleProps) {
		const err = new ClientError(e.response?.data?.message as string);

		err.data = e.response?.data;

		throw err;
	}

	async get(url: string, options: ClientOptions = { timeout: 130000 }) {
		return axios.get(url, options).catch(this.errorHandler);
	}

	async post(url: string, body: unknown = {}, options: ClientOptions = { timeout: 130000 }) {
		return axios.post(url, body, options).catch(this.errorHandler);
	}

	async put(url: string, body: unknown = {}, options: ClientOptions = { timeout: 130000 }) {
		return axios.put(url, body, options).catch(this.errorHandler);
	}

	async delete(url: string, options: ClientOptions = { timeout: 130000 }) {
		return axios.delete(url, options).catch(this.errorHandler);
	}
}

export default Client;
