import { Duration } from "luxon";
import Business from "./business";
import Service from "./service";

class Services {
	services: Service[];
	private business?: Business;

	constructor(services: Service[], apiBusiness?: any) {
		this.services = services;
		this.business = new Business(apiBusiness);
	}

	static fromArray(apiServices: any[], models?: { business: Business }) {
		return new Services(
			apiServices.map(
				(apiService) => new Service(apiService, models?.business)
			),
			models?.business
		);
	}

	get formattedPrice(): string {
		let price = 0;
		let formattedPrice = "";

		this.services.forEach(service => {
			price += service.price;
		});

		try {
			formattedPrice = new Intl.NumberFormat("en-NG", {
				style: "currency",
				currency: this.business?.currency,
			}).format(price);	
		} catch(e) {
			// TODO: handle error on some devices "style must be either decimal percent or currency"
		}

		return formattedPrice;
	}

	get formattedDuration() {
		let minutes = 0;

		this.services.forEach(service => {
			minutes += service.durationInMinutes;
		});

		return Duration.fromMillis(minutes * 60 * 1000).rescale().toHuman({ unitDisplay: "short" });
	}
}

export default Services;
