/* eslint-disable react/display-name */
import { useContext, useMemo, useState } from "react";
import UserContext from "../contexts/user";
import { Button, Spinner } from "@blueprintjs/core";

import * as api from "../api";
import { AppToaster } from "../toaster";

const ResendEmail = () => {
	const [isSending, setIsSending] = useState(false);

	const sendVerificationEmail = async () => {
		setIsSending(true);

		try {
			const resp = await api.user.resendVerifyEmail();

			if (resp?.success) {
				AppToaster.show({
					message: "Email sent",
					intent: "success"
				});
			} else {
				AppToaster.show({
					message: resp?.message,
					intent: "danger"
				});
			}
		} catch (e: any) {
			AppToaster.show({
				message: e.message,
				intent: "danger"
			});
		}finally {
			setIsSending(false);
		}
	};

	return (
		<div className="flex min-h-[90vh] items-center justify-center bg-slate-50">
			<div className="flex max-w-5xl flex-col gap-6">
				<h2 className="text-2xl font-bold">Please verify your email</h2>
				<p className="text-lg">
					An email was sent to you to verify your email. Please open it and
					confirm your email to continue.
					<br />
					<br />
				</p>

				<p className="text-lg">
					If you didn&apos;t receive an email please check your spam. If you
					didn&apos;t find it, please click on &quot;Resend link&quot; below.
				</p>

				<Button onClick={sendVerificationEmail} intent="primary" loading={isSending} disabled={isSending}>
					Resend email
				</Button>

				<p>
					Please reach out to us at{" "}
					<a href="mailto:support@mybookingsapp.com">
						support@mybookingsapp.com
					</a>
					{" "}
					if you are having issues.
				</p>
			</div>
		</div>
	);
};

const Loading = () => {
	return (
		<div className="flex min-h-[90vh] items-center justify-center bg-slate-50">
			<Spinner size={36} />
		</div>
	);
};

const withEmailVerification = () => (Component: any) => (props: any) => {
	const { user, isLoading, error } = useContext(UserContext);

	if (isLoading) {
		return <Loading />;
	}

	if ((error?.data?.code as ApiErrorCode) === "email_not_verified") {
		return <ResendEmail />;
	}

	return useMemo(() => {
		return <Component {...props} />;
	}, [props]);
};

export default withEmailVerification;
