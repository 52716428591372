import useSWR from "swr";

import * as api from "../api";


type useServicesProps = {
	page?: number;
	limit?: number;
}

const useServices = (props: useServicesProps = {}) => {
	const { data, error, isLoading } = useSWR(`/api/services/page=${props.page}`, () => api.service.getServices({ params: props }));

	return {
		services: data,
		isLoading,
		isError: error
	};
};

export default useServices;