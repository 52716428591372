interface ILocation {
	address: string;
	lng: number;
	lat: number;
}

class Location {
	address?: string;
	lat?: number;
	lng?: number;

	constructor(location: ILocation) {
		if (location) {
			this.address = location.address;
			this.lat = location.lng;
			this.lng = location.lat;
		}
	}
}

class Business {
	acceptPayment: boolean;
	name: string;
	currency: string;
	url: string;
	slug: string;
	phone: string;
	bookingLink: string;
	whatsappLink?: string;
	location: Location;

	constructor(apiBusiness: any) {
		this.acceptPayment = Boolean(apiBusiness?.accept_payment);
		this.name = apiBusiness?.name;
		this.currency = apiBusiness?.currency;
		this.url = apiBusiness?.url;
		this.slug = apiBusiness?.slug;
		this.bookingLink = apiBusiness?.booking_link;
		this.whatsappLink = apiBusiness?.whatsappLink;
		this.phone = apiBusiness?.phone;
		this.location = new Location(apiBusiness?.location);
	}
}

export default Business;
