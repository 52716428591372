/* eslint-disable react/display-name */
import { useContext, useMemo } from "react";

import UserContext from "../contexts/user";
import { DateTime } from "luxon";
import { Spinner } from "@blueprintjs/core";

const UserDeletion = ({ date }: { date?: string }) => {
	return (
		<div className="flex min-h-[90vh] items-center justify-center bg-slate-50">
			<div className="flex max-w-5xl flex-col gap-6">
				<h2 className="text-2xl font-bold">
					This page has been scheduled to be deleted
				</h2>
				<p className="text-lg">
					Please reach out to us at{" "}
					<a href="mailto:support@mybookingsapp.com">
						support@mybookingsapp.com
					</a>
					<br></br>
					if you want your account to be reactivated.
				</p>
				{date && (
					<p className="text-lg">
						If you do nothing, your account will be deleted within{" "}
						<b>
							{DateTime.fromJSDate(new Date(date))
								.diff(DateTime.now())
								.toFormat("d")}{" "}
							days
						</b>
						.
					</p>
				)}
			</div>
		</div>
	);
};

const Loading = () => {
	return (
		<div className="flex min-h-[90vh] items-center justify-center bg-slate-50">
			<Spinner size={36}/>
		</div>
	);
};

const withUserDeletion = () => (Component: any) => (props: any) => {
	const { user, isLoading } = useContext(UserContext);

	if (user?.scheduledDeletion) {
		return <UserDeletion date={user?.scheduledDeletion} />;
	}

	if (isLoading) {
		return <Loading />;
	}

	return useMemo(() => {
		return (
			<>
				<Component {...props} />
			</>
		);
	}, [props]);
};

export default withUserDeletion;
