import { Card, CardProps, Classes, Elevation } from "@blueprintjs/core";
import Booking, { BookingState } from "../../../models/booking";
import Service from "../../../models/service";


interface BookingCardProps {
	title?: string;
	booking?: Booking;
	loading?: boolean;
}

const PaymentTag = ({ state }: { state: BookingState }) => {
	const getColorClass = (state: BookingState) => {
		switch(state) {
		case "CONFIRMED":
			return "bg-green-600";
		case "PAYMENT_INIT_SUCCESS":
		case "PENDING_PAYMENT":
		case "PENDING":
			return "bg-amber-600";
		case "PAYMENT_INIT_FAILED":
		case "CANCELLED_FOR_NON_PAYMENT":
			return "bg-red-600";
		default:
			return "bg-blue-600";
		}
	};

	const getText = (state: BookingState) => {
		switch(state) {
		case "CONFIRMED":
			return "PAID";
		case "PAYMENT_INIT_SUCCESS":
		case "PENDING_PAYMENT":
		case "PENDING":
			return "PENDING";
		case "PAYMENT_INIT_FAILED":
		case "CANCELLED_FOR_NON_PAYMENT":
			return "FAILED";
		default:
			return "ERROR";
		}
	};


	return (
		<div
			className={`px-1 rounded-sm w-16 flex justify-center py-0.5 mb-1  ${getColorClass(state)}`}
		>
			<span className="text-xs text-white">
				{getText(state)}
			</span>
		</div>
	);
};

const BookingCard = (props: BookingCardProps & CardProps) => {
	const { loading, booking, ...cardProps } = props;

	const startTime = booking?.formattedStartTime;
	const endTime= booking?.formattedEndTime;
	const date = booking?.formattedDate;
	const duration = booking?.fromattedDuration;
	const title = booking?.title;
	const services = booking?.services.services.map((s: Service) => {
		return {
			price: s.formattedPrice,
			title: s.title,
		};
	});


	const loadingClass = loading ? Classes.SKELETON : "";

	return (
		<Card
			className="flex min-h-[200px] flex-col justify-between"
			interactive
			elevation={Elevation.TWO}
			{...cardProps}
		>
			<div>
				<h2
					className={`${"mb-3 text-lg font-bold"} ${loadingClass}`}
				>
					{title}
				</h2>
				{
					booking?.hasCharge && <PaymentTag state={booking.state} />
				}
				<div className={loadingClass}>
					<p>
						<b>Booking</b>
					</p>
					<p>{date}</p>
					<p>
						{startTime} - {endTime}
					</p>
					<p>{duration}</p>
				</div>
				<div className={`mt-2 ${loadingClass}`}>
					<p>
						<b>Services</b>
					</p>
					<ul>
						{services?.map((service) => {
							return <li key={service.title}>{service.title}</li>;
						})}
					</ul>
				</div>
			</div>
		</Card>
	);
};

export default BookingCard;
