import { ReactNode } from "react";

type SettingsSectionProps = {
	children: ReactNode | ReactNode[];
	title: string;
};

const SettingsSection = (props: SettingsSectionProps) => {
	return (
		<div className="p-8 bg-white shadow-md rounded-sm">
			<h2 className="text-lg mb-5">
				{props.title}
			</h2>
			<div>
				{props.children}
			</div>
		</div>
	);
};

export default SettingsSection;
