import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";
import {
	InputGroup,
	InputGroupProps,
	Menu,
	MenuItem,
	Spinner,
} from "@blueprintjs/core";

export type AutocompletResult = {
	address: string;
	lat: number;
	lng: number;
};

export type AutocompleteInputProps = {
	onSelect: (props: AutocompletResult) => void;
	defaultAddress?: string;
};

const AutocompleteInput = (props: AutocompleteInputProps & InputGroupProps) => {
	const { onSelect, defaultAddress, ...otherProps } = props;
	const {
		ready,
		value,
		suggestions: { data, loading },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		defaultValue: defaultAddress,
		requestOptions: {
			/* Define search scope here */
		},
		debounce: 300,
	});

	const handleSelect =
		({ description }: { description: string }) =>
			async () => {
				// When user selects a place, we can replace the keyword without request data from API
				// by setting the second parameter to "false"
				setValue(description, false);
				clearSuggestions();

				// Get latitude and longitude via utility functions
				const results = await getGeocode({ address: description });
				const { lat, lng } = getLatLng(results[0]);

				onSelect({ lat, lng, address: description });
			};

	return (
		<div>
			<InputGroup
				type="text"
				{...otherProps}
				value={value}
				disabled={!ready}
				onChange={(evt) => {
					setValue(evt.target.value);
				}}
				rightElement={loading ? <Spinner size={20} /> : undefined}
			/>
			{!loading && !!data.length && (
				<Menu className="absolute z-30 border shadow-md">
					{data.map((place) => {
						const {
							place_id,
							structured_formatting: { main_text, secondary_text },
						} = place;

						return (
							<MenuItem
								key={place_id}
								onClick={handleSelect(place)}
								text={
									<>
										<strong>{main_text}</strong> <small>{secondary_text}</small>
									</>
								}
							/>
						);
					})}
				</Menu>
			)}
		</div>
	);
};

export default AutocompleteInput;
