import { useEffect } from "react";
import { Spinner } from "@blueprintjs/core";

import { useNavigate } from "react-router-dom";

const AuthCallbackPage = () => {
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate("/dashboard");
		}, 100);
	}, []);

	return (
		<div className="h-screen justify-center items-center">
			<Spinner />
		</div>
	);
};

export default AuthCallbackPage;