/* eslint-disable react/display-name */
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import OnboardingDialog from "../components/organisms/OnboardingPageDialog";

import { PAGE_DIALOG_ID } from "../types";

export const DIALOG_PARAM = "d";

const KEYS: { [key in string]: PAGE_DIALOG_ID } = {
	ONBOARDING: "onboarding",
};

const withPageDialog =
	(ids?: PAGE_DIALOG_ID[]) => (Component: any) => (props: any) => {
		const [searchParams, setSearchParams] = useSearchParams();
		const dialog: PAGE_DIALOG_ID | null = searchParams.get(
			DIALOG_PARAM
		) as PAGE_DIALOG_ID | null;

		const canShow = (id: PAGE_DIALOG_ID): boolean => {
			if (ids && dialog && ids.includes(dialog) && id === dialog) {
				return true;
			}
			return false;
		};

		const onClose = () => {
			setSearchParams((prevParams) => {
				prevParams.delete(DIALOG_PARAM);
				return new URLSearchParams(prevParams.toString());
			});
		};

		return useMemo(() => {
			return (
				<>
					<Component {...props} />
					<OnboardingDialog
						isOpen={canShow(KEYS.ONBOARDING)}
						onClose={onClose}
					/>
				</>
			);
		}, [props]);
	};

export default withPageDialog;
