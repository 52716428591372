import { useContext, useState } from "react";
import { Tab, Tabs, Intent, Dialog } from "@blueprintjs/core";
import { useNavigate, useSearchParams } from "react-router-dom";

import BookingsTab, { TAB_ID as BOOKINGS_ID } from "./bookings";
import ServicesTab, { TAB_ID as SERVICES_ID } from "./services";
import ScheduleTab, { TAB_ID as SCHEDULE_ID } from "./schedule";
import Button from "../../components/atoms/Button";
import BusinessContext from "../../contexts/business";
import PageHeading from "../../components/organisms/PageHeading";
import URLS from "../../utils/urls";
import ShareSocial from "../../components/organisms/ShareSocial";
import withPageDialog from "../../hoc/withPageDialog";
import withBusiness from "../../hoc/withBusiness";

const urls = new URLS();

const DashboardPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [showShareDialog, setShowShareDialog] = useState(false);
	const [selectedTabId, setSelectedTabId] = useState(
		searchParams.get("tab") || SERVICES_ID
	);
	const { business } = useContext(BusinessContext);
	const navigate = useNavigate();

	const handleTabChange = (newTabId: string) => {
		setSearchParams((prevParams) => {
			prevParams.set("tab", newTabId);
			prevParams.set("page", "0");

			return new URLSearchParams(prevParams.toString());
		});
		setSelectedTabId(newTabId);
	};

	return (
		<>
			<PageHeading
				title={business?.name}
				renderSubtitle={() => {
					return (
						<a
							href={`https://mybookingsapp.com/${business?.slug}`}
							target="_blank"
							rel="noreferrer"
						>
							<span className="text-slate-500">
								{`mybookingsapp.com/${business?.slug}`}
							</span>
						</a>
					);
				}}
				renderRight={() => (
					<div className="flex flex-row gap-3">
						<Button
							icon="link"
							text="Booking link"
							intent="primary"
							onClick={() => setShowShareDialog(true)}
						/>
						<Button
							onClick={() => navigate(urls.create_service.path)}
							text="Create Service"
							intent={Intent.PRIMARY}
							icon="add"
						/>
					</div>
				)}
			/>

			<Tabs
				id="dashboard"
				onChange={handleTabChange}
				selectedTabId={selectedTabId}
			>
				<Tab id={SERVICES_ID} title="Services" panel={<ServicesTab />} />
				<Tab id={BOOKINGS_ID} title="Bookings" panel={<BookingsTab />} />
				<Tab id={SCHEDULE_ID} title="Schedule" panel={<ScheduleTab />} />
				<Tabs.Expander />
			</Tabs>
			<Dialog
				isOpen={showShareDialog}
				onClose={() => setShowShareDialog(false)}
			>
				<ShareSocial
					link={business?.bookingLink || ""}
					description={`Hi Guys, good news!

You can now easily book my services on ${business?.bookingLink}.
							
The process is simple, fast and will prevent any mix up with other appointments 🤗
						`}
				/>
			</Dialog>
		</>
	);
};

export default withBusiness()(withPageDialog(["onboarding"])(DashboardPage));
