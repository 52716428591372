import useSWR from "swr";

import * as api from "../api";

const useService = (id: string) => {
	const { data, error, isLoading } = useSWR(`/api/service/${id}`, () => api.service.getService(id));

	return {
		service: data,
		isLoading,
		isError: error
	};
};

export default useService;