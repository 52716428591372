import { Button, Dialog } from "@blueprintjs/core";

import * as api from "../../api";
import { AppToaster } from "../../toaster";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import URLS from "../../utils/urls";

type ArchiveDialogProps = {
	serviceId?: string;
	isOpen?: boolean;
	onClose?: () => void;
};

const ArchiveDialog = ({ isOpen, onClose, serviceId }: ArchiveDialogProps) => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const urls = new URLS();

	const onArchive = async () => {
		setLoading(true);

		try {
			if (serviceId) {
				await api.service.archiveService(serviceId);
				navigate(urls.dashboard_services.path);

				AppToaster.show({
					message: "Service Archived",
					intent: "success",
					icon: "tick",
				});
			}
		} catch(e) {
			AppToaster.show({
				message: "Could not archive service",
				intent: "success",
				icon: "tick",
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog
			isOpen={isOpen}
			onClose={onClose}
			className="relative mx-3 flex max-w-lg items-center justify-center"
		>
			<div className="h-16"></div>
			<div className="mb-6 flex max-w-sm flex-col gap-4 px-3">
				<h2 className="text-center text-xl font-medium">Archive Service</h2>
				<p className="text-center sm:text-base md:text-lg">
					Archiving this service will make it unavailable for your customers to book. Are you sure you want to archive?
				</p>
				<div className="flex flex-row gap-4 justify-center" >
					<Button
						onClick={onArchive}
						large
						intent="danger"
						loading={loading}
						className="w-24"
						disabled={loading}
					>
						Yes
					</Button>
					<Button
						onClick={onClose}
						large
						intent="primary"
						className="w-24"
						disabled={loading}
					>
						No
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default ArchiveDialog;
