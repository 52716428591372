import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { useState } from "react";

import SettingsSection from "./SettingsSection";


type PrivacySectionProps = {
	onDelete: () => Promise<void>
}

const PrivacySection = (props: PrivacySectionProps) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const onDelete = () => {
		setIsDialogOpen(true);
	};

	const onCloseDialog = () => {
		setIsDialogOpen(false);
	};

	const onDeleteAccepted = async () => {
		setIsSubmitting(true);

		try {
			await props.onDelete();
		} catch(e) {
			// TODO: handle
		}
	};

	return (
		<SettingsSection title="Privacy">
			<Button intent="danger" onClick={onDelete} text="Delete Account" />

			<Dialog isOpen={isDialogOpen} onClose={onCloseDialog}>
				<DialogBody>
					<h2 className="text-xl">
						<b>Are you sure you want to delete your account?</b>
					</h2>
					<br></br>
					<p>
						If you select yes, your account will be scheduled to be deleted
						within <b>14 days</b>. Once this process is done,{" "}
						<b className="underline">
							you will not be able to recover your account anymore and all your
							data will be gone.
						</b>
					</p>
					<br></br>
					<p>
						<b>
							We will reach out to you for one more confirmation via email after
							which we will proceed with the account deletion.
						</b>
					</p>
					<br></br>
					<p className="text-red-500 italic">
						<b>Please note that this will also delete every business that you created and its related data.</b>
					</p>
				</DialogBody>
				<DialogFooter>
					<div className="flex gap-5">
						<Button
							disabled={isSubmitting}
							onClick={onCloseDialog}
							intent="primary"
							className="w-16"
						>
							No
						</Button>
						<Button
							disabled={isSubmitting}
							loading={isSubmitting}
							intent="danger"
							className="w-16"
							onClick={onDeleteAccepted}
						>
							Yes
						</Button>
					</div>
				</DialogFooter>
			</Dialog>
		</SettingsSection>
	);
};

export default PrivacySection;
