import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AxiosError } from "axios";

import Client from "../api/client";
import LocalStorage from "../lib/local-storage";

type AuthContextProps = {
	children: ReactNode
};

type AuthContextValue = {
	logout?: () => void;
	isLoading?: boolean;
	isAuthenticated?: boolean;
	clientAuthLoading?: boolean;
}

const AuthContext = createContext<AuthContextValue>({});
const localStorage = LocalStorage.getInstance();
const TOKEN_KEY = "TK";

const unauthorizedResponseInterceptor = (cb: () => void) => (error: AxiosError<any, any>) => {
	if (error.response?.status === 401) {
		return cb();
	}

	return Promise.reject(error);
};

export const AuthProvider = (props: AuthContextProps) => {
	const { isAuthenticated, isLoading, logout, getAccessTokenSilently } = useAuth0();
	const [value, setValue] = useState<AuthContextValue>({ isLoading, clientAuthLoading: true });
	const [isTokenSet, setIsTokenSet] = useState(false);

	useEffect(() => {
		setValue(v => {
			return {
				...v,
				isAuthenticated: isAuthenticated && isTokenSet,
				isLoading,
				logout: logoutUser
			};
		});
	}, [isAuthenticated, isLoading, isTokenSet]);

	useEffect(() => {
		(async () => {
			let token = localStorage.get(TOKEN_KEY);

			if (!isLoading && !isTokenSet && !token) {
				setValue(v => {
					return {
						...v,
						clientAuthLoading: false
					};
				});

				try {
					token = await getAccessTokenSilently({ scope: "openid profile email offline_access" });
					localStorage.set(TOKEN_KEY, token);
				} catch(e) {
					// log error
				}
			}

			if (!isTokenSet && token) {
				Client.setToken(token);
				setIsTokenSet(true);

				setValue(v => {
					return {
						...v,
						clientAuthLoading: false
					};
				});
			}
		})();
	}, [isLoading]);

	const logoutUser = useCallback(() => {
		localStorage.remove(TOKEN_KEY);
		logout({ returnTo: window.location.origin });
	}, []);

	useMemo(() => {
		Client.addErrorInterceptor(unauthorizedResponseInterceptor(logoutUser));
	}, [logout]);


	return (
		<AuthContext.Provider value={value}>
			{props.children}
		</AuthContext.Provider>
	);
};


export default AuthContext;
