import useSWR from "swr";

import * as api from "../api";

const useExportCount = () => {
	const { data, error, isLoading, mutate } = useSWR("/api/exports/count", () =>
		api.exports.count()
	);

	return {
		availability: data,
		isLoading,
		isError: error,
		mutate,
	};
};

export default useExportCount;
