import { Alignment, Classes, Navbar, NavbarProps, Button, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { useNavigate, Link } from "react-router-dom";

import TextLogo from "../../../assets/images/svgs/text-logo-black.svg";

type CustomNavbarProps = {
	onLogout?: () => void;
	navbarProps?: NavbarProps
};

const CustomNavbar = (props: CustomNavbarProps) => {
	const nProps = props.navbarProps || {};
	const navigate = useNavigate();

	return (
		<div className="h-[50px]">
			<Navbar fixedToTop {...nProps}>
				<Navbar.Group align={Alignment.LEFT}>
					<Navbar.Heading>
						<Link to="/dashboard">
							<img src={TextLogo} alt="text logo" className="h-5" />
						</Link>
					</Navbar.Heading>
				</Navbar.Group>
				<Navbar.Group align={Alignment.RIGHT}>
					<Popover
						interactionKind="click"
						popoverClassName={Classes.POPOVER_CONTENT_SIZING}
						placement="bottom"
						modifiers={{
							arrow: { enabled: false }
						}}
						content={
							<Menu>
								<MenuItem icon="user" onClick={() => navigate("/dashboard/profile")} text="Profile" />
								<MenuItem icon="log-out" onClick={props.onLogout} text="logout" />
							</Menu>
						}
						renderTarget={({ isOpen, ref, ...targetProps }) => (
							<Button
								className="bp5-minimal"
								icon="menu"
								{...targetProps}
								ref={ref}
							/>
						)}
					/>
				</Navbar.Group>
			</Navbar>
		</div>
	);
};

export default CustomNavbar;
