import { useAuth0 } from "@auth0/auth0-react";
import { Collapse, Icon } from "@blueprintjs/core";
import { useState } from "react";

import Footer from "../../components/organisms/Footer";
import BookOpenSVG from "../../assets/images/svgs/icons/book-open.svg";
import CalendarSVG from "../../assets/images/svgs/icons/calendar.svg";
import ClipboardSVG from "../../assets/images/svgs/icons/clipboard.svg";
import DownloadAndroidSVG from "../../assets/images/svgs/downloadAndroid.svg";
import DownloadAppleSVG from "../../assets/images/svgs/downloadApple.svg";
import TextLogoSVG from "../../assets/images/svgs/text-logo-blue.svg";
import AppBookingPNG from "../../assets/images/phone-bookings.png";
import EclipseLargePNG from "../../assets/images/eclipse-full.png";
import PhoneRightSlantPNG from "../../assets/images/phone-right-slant.png";
import PhoneLeftSlantPNG from "../../assets/images/phone-left-slant.png";

import "./index.scss";

import FAQS from "./faqs";

const Header = () => {
	return (
		<div className="flex items-center justify-between px-6 py-4 drop-shadow-md bg-[#F8FAFC] border-b-2 ">
			<a href="/">
				<img className="h-7" alt="logo" src={TextLogoSVG} />
			</a>
			<a href="https://chat.whatsapp.com/KOfgMwEA4TJA6GVINeFksU" className="flex gap-2 items-center">
				Join our community
				<Icon icon={"arrow-right"} />
			</a>
		</div>
	);
};

const IntroSection = () => {
	const { loginWithRedirect } = useAuth0();

	return (
		<div className="relative grid max-sm:min-h-[750px] md:min-h-[800px] grid-cols-2 overflow-hidden bg-[#F8FAFC]">
			<img
				className="z-1 absolute bottom-0 right-[20%] h-[590px] max-md:hidden lg:block"
				src={PhoneLeftSlantPNG}
			/>
			<img
				className="absolute bottom-0 right-[10%] z-[10] h-[690px] max-md:hidden lg:block"
				src={PhoneRightSlantPNG}
			/>
			<div className="info-section__bottom-rectangle-cover" />

			<div className="info-section__content flex items-center justify-center px-6 sm:w-[100vw] lg:w-[50vw]">
				<div className="flex max-w-xl flex-col gap-6 max-sm:items-center">
					<img className="w-72" alt="logo" src={TextLogoSVG} />
					<h2 className="text-3xl font-bold max-sm:text-center">
						The ultimate Scheduling and <br />
						Booking <span className="text-bkn-green">Solution</span>.
					</h2>
					<p className="text-base max-sm:text-center">
						Focus on other important things while we manage your entire booking
						process and give you the much needed time to concentrate on
						providing top-notch services to your clients.
					</p>
					<div>
						<div className="flex flex-row items-center gap-4">
							<a
								onClick={loginWithRedirect}
								className="flex items-center gap-2 rounded-md border-2 border-bkn-blue-900 px-4 py-2 max-sm:text-lg md:text-base text-bkn-blue-900 hover:no-underline"
							>
								Get Started
								<Icon icon={"arrow-right"} />
							</a>
							<a
								href="#app-section"
								className="flex items-center gap-2 rounded-md bg-bkn-blue px-4 py-2 max-sm:text-lg md:text-base text-white no-underline hover:no-underline border-2 border-bkn-blue"
							>
								Get the App
								<Icon icon={"arrow-right"} />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const AppSection = () => {
	return (
		<div
			id="app-section"
			className="relative px-6 max-sm:flex max-sm:flex-col max-sm:gap-10 md:grid md:grid-cols-2"
		>
			<div className="absolute inset-0 -z-10 flex items-center justify-center overflow-hidden">
				<img className="h-[90%] sm:hidden md:block" src={EclipseLargePNG} />
			</div>
			<div className="flex justify-center">
				<img alt="circles" src={AppBookingPNG} />
			</div>
			<div className="flex items-center justify-center md:px-16">
				<div className="flex max-w-xl flex-col gap-4 max-sm:items-center">
					<h2 className="text-3xl font-bold max-sm:text-center">
						Are you concerned about double bookings or missed appointments?
					</h2>
					<p className="text-base max-sm:text-center">
						We got you covered!
						<br />
						Stay updated with instant booking notifications and timely reminders
						for upcoming bookings.
					</p>

					<div>
						<p className="text-base font-semibold max-sm:text-center">
							Download our app here
						</p>
						<div className="flex flex-row items-center">
							<a href="https://apps.apple.com/us/app/my-bookings-app/id6449464149">
								<img
									className="w-40"
									src={DownloadAppleSVG}
									alt="Download apple app"
								/>
							</a>
							<a href="https://play.google.com/store/apps/details?id=com.mybookingsapp.app">
								<img
									className="w-52"
									src={DownloadAndroidSVG}
									alt="Download android app"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const WhyChooseSection = () => {
	const data = [
		{
			icon: ClipboardSVG,
			description:
				"With mybookings you will be able to add all the services you offer and your customers will be able to book one or many services at once",
		},
		{
			icon: BookOpenSVG,
			description:
				"Say goodbye to double bookings, you don’t need to keep a journal for all your bookings. Now, you can simply access available reservations on the bookings tab.",
		},
		{
			icon: CalendarSVG,
			description:
				"Set your own schedule and availability to fit your business needs. Your clients can easily book sessions with you by selecting from your available times.",
		},
	] as { icon: string; description: string }[];

	return (
		<div className="px-6 py-4">
			<h2 className="mt-7 mb-4 text-center text-3xl font-semibold">
				Why choose MyBookings?
			</h2>
			<div className="max-sm:flex max-sm:flex-col max-sm:gap-4 md:grid md:grid-cols-3">
				{data.map((sect, idx) => {
					return (
						<div
							key={idx}
							className="flex flex-col justify-center gap-4 rounded-xl border border-black px-6 py-6 md:mx-8"
						>
							<img src={sect.icon} className="h-10" />
							<p className="text-center">{sect.description}</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const FaqsSection = ({ faqs }: { faqs: { q: string; a: string }[] }) => {
	const [active, setActive] = useState("");
	return (
		<div id="faq" className="px-6 pt-1 pb-12">
			<h2 className="mt-7 mb-4 text-center text-3xl font-semibold">
				Frequently Asked Questions (FAQs)
			</h2>
			<div className="flex w-full flex-col gap-3">
				{faqs.map((faq) => {
					const isActive = active === faq.q;

					return (
						<div
							className="flex cursor-pointer flex-row justify-between rounded-md bg-slate-100 px-4 py-2 shadow-md"
							key={faq.q}
							onClick={() => {
								if (!isActive) {
									setActive(faq.q);
								} else {
									setActive("");
								}
							}}
						>
							<div>
								<h3
									className={`text-lg font-medium ${
										isActive ? "text-bkn-green" : ""
									}`}
								>
									{faq.q}
								</h3>
								<Collapse isOpen={isActive}>
									<p className={`text-base ${isActive ? "text-gray-800" : ""}`}>
										{faq.a}
									</p>
								</Collapse>
							</div>
							<div className="mr-2 flex justify-center">
								<Icon
									className="flex items-center"
									icon={isActive ? "minus" : "plus"}
									size={20}
								/>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const LandingPage = () => {
	return (
		<>
			<Header />
			<div className="flex flex-col gap-8">
				<IntroSection />

				<AppSection />

				<WhyChooseSection />

				<FaqsSection faqs={FAQS} />

				<Footer />
			</div>
		</>
	);
};

export default LandingPage;
