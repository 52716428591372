import { Button, Checkbox, FormGroup, Icon, Spinner, Switch, Tooltip } from "@blueprintjs/core";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { DateTime } from "luxon";
import { get } from "lodash";

import EmptyState from "./emptyState";
import LoadingState from "./loadingState";
import useSchedule from "../../hooks/useSchedule";
import TimeInput, { TIME_FORMAT } from "../../components/molecules/TimeInput";
import { TIME_DAY } from "../../models/schedule";
import * as api from "../../api";
import { AppToaster } from "../../toaster";

export const TAB_ID = "schedule";

const timeSchema = yup
	.object({
		intervals: yup
			.array()
			.of(
				yup.object({
					start: yup.string(),
					end: yup
						.string()
						.when("start", (start, schema) => {
							// This ensures that the end time is always greater than the start time
							if (start) {
								return schema.test(
									"is-allowed",
									({ label }: { label: string }) =>
										`${label} should be greater than start time`, // a message can also be a function
									(value: string) => {
										return value > start;
									}
								);
							}
						})
						.label("End time"),
				})
			)
			.required(),
	})
	.default(null)
	.nullable();

const validationSchema = yup.object({
	name: yup.string().required(),
	time_zone: yup.string().required(),
	allow_double_booking: yup.boolean().default(false),
	times: yup
		.object({
			mon: timeSchema.nullable(),
			tue: timeSchema.nullable(),
			wed: timeSchema.nullable(),
			thur: timeSchema.nullable(),
			fri: timeSchema.nullable(),
			sat: timeSchema.nullable(),
			sun: timeSchema.nullable(),
		}),
});

const ScheduleTab = () => {
	const { schedule, isLoading } = useSchedule();

	const handleFormSubmit = async (values: any, options: FormikHelpers<any>) => {
		options.setSubmitting(true);

		try {
			await api.schedule.updateSchedule(values);
	
			AppToaster.show({
				message: "Schedule Successfully updated",
				intent: "success",
				icon: "tick",
			});
		} catch (e) {
			AppToaster.show({
				message: "Something went wrong",
				intent: "danger",
				icon: "issue",
			});
		}
	
		options.setSubmitting(false);
	};

	const formik = useFormik({
		validationSchema,
		initialValues: {
			name: schedule?.name,
			time_zone: schedule?.timeZone,
			times: schedule?.times,
			allow_double_booking: schedule?.allowDoubleBooking
		},
		onSubmit: handleFormSubmit,
		enableReinitialize: true,
	});

	if (isLoading) {
		return <Spinner />;
	}

	const emptyDesign = "flex flex-col justify-center items-center gap-9";
	const nonEmptyDesign = "w-full";
	const isEmpty = !isLoading && !schedule;

	return (
		<div className="flex w-full flex-col items-center">
			<div className={isEmpty ? emptyDesign : nonEmptyDesign}>
				{isLoading && <LoadingState />}
				{!isLoading && schedule ? (
					<div className="w-full">
						<FormGroup
							label={
								<div className="flex flex-row items-center gap-1">
									<span>
										<b>Allow Double Booking</b>
									</span>
									<Tooltip
										content={
											<span>
													This allows your clients book the same slot multiple times.
											</span>
										}
									>
										<Icon icon="info-sign" />
									</Tooltip>
								</div>
							}
						>
							<Switch checked={formik.values.allow_double_booking} onChange={formik.handleChange("allow_double_booking")}/>
						</FormGroup>
						<p className="mb-5 font-semibold underline">
							Set your weekly hours
						</p>
						<form onSubmit={formik.handleSubmit}>
							<div className="my-6 flex w-full flex-col gap-4">
								{(Object.keys(formik.values.times || {}) as TIME_DAY[]).map(
									(key: TIME_DAY) => {
										if (formik.values.times) {
											const time = formik.values.times[key];
											const timesCopy = { ...formik.values.times };

											const onCheck = () => {
												if (time) {
													timesCopy[key] = undefined;
												} else {
													timesCopy[key] = {
														intervals: [{
															start: "09:00",
															end: "19:00"
														}]
													};
												}
												formik.setFieldValue("times", timesCopy);
											};

											const isAvailable = !!time;
											const hasIntervals = !!time?.intervals && time.intervals.length;

											return (
												<div
													key={key}
													className="flex w-full flex-row items-center bg-white p-5 outline outline-1 outline-slate-200"
												>
													<div className="flex flex-row items-center">
														<Checkbox
															className="mb-[-2px]"
															checked={isAvailable}
															onChange={onCheck}
														/>
														<p>{key.toUpperCase()}</p>
													</div>
													<div className="ml-4">
														{!hasIntervals &&
															<div>
																<p>No availability set</p>
															</div>
														}
														{time?.intervals.map((interval, idx) => {
															const startDt = DateTime.fromFormat(
																interval.start,
																TIME_FORMAT
															);
															const endDt = DateTime.fromFormat(
																interval.end,
																TIME_FORMAT
															);

															const timesCopy = { ...formik.values.times };

															const onStartTime = (date: DateTime) => {
																const localTime = timesCopy[key];

																if (localTime) {
																	localTime.intervals[idx].start =
																		date.toFormat(TIME_FORMAT);
																}

																// Using object.assign here is important to tell formik that the value should be updated
																formik.setFieldValue("times", Object.assign({}, timesCopy));
															};
															const onEndTime = (date: DateTime) => {
																const localTime = timesCopy[key];

																if (localTime) {
																	localTime.intervals[idx].end =
																		date.toFormat(TIME_FORMAT);
																}

																formik.setFieldValue("times", timesCopy);
															};

															return (
																<div key={idx}>
																	<div className="flex flex-row items-center gap-4">
																		<TimeInput
																			defaultValue={startDt}
																			onTime={onStartTime}
																		/>
																		<span>-</span>
																		<TimeInput
																			defaultValue={endDt}
																			onTime={onEndTime}
																		/>
																	</div>
																	<p>
																		{get(
																			formik.errors,
																			`times[${key}].intervals[${idx}].end`
																		)}
																	</p>
																</div>
															);
														})}
													</div>
												</div>
											);
										}
									}
								)}
							</div>

							<Button
								intent="primary"
								type="submit"
								text="Update"
								disabled={formik.isSubmitting}
								loading={formik.isSubmitting}
							/>
						</form>
					</div>
				) : (
					<EmptyState />
				)}
			</div>
		</div>
	);
};

export default ScheduleTab;
