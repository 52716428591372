import * as yup from "yup";

export const businessSchema = yup.object({
	name: yup.string().required(),
	slug: yup.string().required(),
	whatsapp_link: yup.string().url().label("WhatsApp Link"),
	url: yup.string().url().label("Business website"),
	phone: yup.string().required(),
	location: yup
		.object({
			address: yup.string(),
			lat: yup.number().nullable(),
			lng: yup.number().nullable(),
		})
		.nullable(),
});

export const bankSchema = yup.object({
	name: yup.string().required("Select a bank").label("Bank Name"),
	account_number: yup.string().required(),
	bank_code: yup.string().required("Select a bank"),
	currency: yup.string().required().oneOf(["NGN"]),
});

export const exportSchema = yup.object({
	start_date: yup.string().required().label("Start Date"),
	end_date: yup.string().required().label("End Date"),
});
