import { useState } from "react";
import Carousel from "nuka-carousel";
import { Button, Dialog } from "@blueprintjs/core";

import ServicesVector from "../../../assets/images/svgs/onboarding/services.svg";
import BookingsVector from "../../../assets/images/svgs/onboarding/bookings.svg";
import CalendarVector from "../../../assets/images/svgs/onboarding/calendar.svg";
import RelaxVector from "../../../assets/images/svgs/onboarding/relax.svg";
import ShareVector from "../../../assets/images/svgs/onboarding/share.svg";
import { IPageDialog } from "../../../types";

type PanelProps = {
	title: string;
	description: string;
	image: string;
}

type OnboardingDialogProps = {
	isOpen: boolean;
}

const Panel = (props: PanelProps) => {
	return (
		<div className="p-4">
			<div className="flex justify-center items-center bg-slate-200 rounded-md py-4">
				<img src={props.image} className="h-60" />
			</div>
			<h1 className="font-bold text-xl text-center mt-4">
				{props.title}
			</h1>
			<p className="text-center">
				{props.description}
			</p>
			<div className="h-16" />
		</div>
	);
};

const OnboardingDialog = (props: OnboardingDialogProps & IPageDialog) => {
	const [isOpen, setIsOpen] = useState(props.isOpen);
	const panels = [
		{
			title: "Services",
			description: "Get unique links for each service you offer. For example, as a make-up artist, you can create a service link for bridal makeup and another one for regular makeup.",
			image: ServicesVector
		},
		{
			title: "Schedules",
			description: "Set your own schedule and availability to fit your business needs. Your clients can easily book sessions with you by selecting from your available times. ",
			image: CalendarVector
		},
		{
			title: "Bookings",
			description: "Say goodbye to double bookings, you don’t need to keep a journal for all your bookings. Now, you can simply access available reservations on the bookings tab.",
			image: BookingsVector
		},
		{
			title: "Share",
			description: "Now, you can easily share your business link with all of your clients and also put it up on your socials. Tap on ‘share’ to get started.",
			image: ShareVector
		},
		{
			title: "Relax",
			description: "Having your clients book an appointment shouldn’t be stressful, now you can relax and allow MyBookings handle your booking and scheduling needs.",
			image: RelaxVector
		}
	];

	const closeDialog = () => {
		setIsOpen(false);
		props.onClose();
	};

	return (
		<Dialog isOpen={isOpen} className="bg-white max-w-[95vw]" onClose={closeDialog}>
			<Carousel
				autoplay
				autoplayInterval={5000} // 5 Seconds
				defaultControlsConfig={{
					pagingDotsClassName: "px-1"
				}}
				renderCenterLeftControls={({ previousDisabled, previousSlide }) => (
					<Button
						onClick={previousSlide}
						disabled={previousDisabled}
						className="absolute bottom-10 left-5 w-20"
						intent="primary"
					>
						Previous
					</Button>
				)}
				renderCenterRightControls={({ nextDisabled, nextSlide }) => {
					if (nextDisabled) {
						return (
							<Button
								onClick={closeDialog}
								className="absolute bottom-10 right-5 w-20"
								intent="primary"
							>
								Close
							</Button>
						);
					} else {
						return (
							<Button
								onClick={nextSlide}
								disabled={nextDisabled}
								className="absolute bottom-10 right-5 w-20"
								intent="primary"
							>
								Next
							</Button>
						);
					}
				}}
			>
				{panels.map((panel, idx) => {
					return (
						<Panel
							key={idx}
							{...panel}
						/>
					);
				})}
			</Carousel>
		</Dialog>
	);
};

export default OnboardingDialog;
