import { Button, Dialog } from "@blueprintjs/core";
import Lottie from "lottie-react";

import successConfetti from "../../assets/lotties/success-confetti.json";

type SuccessDialogProps = {
	isOpen?: boolean;
	onClose?: () => void;
};

const lottieStyle = {
	height: 280,
};

const SuccessDialog = (props: SuccessDialogProps) => {
	return (
		<Dialog
			isOpen={props.isOpen}
			onClose={props.onClose}
			className="relative flex justify-center items-center mx-3 max-w-sm"
		>
			<div className="absolute top-[-140px]">
				<Lottie
					animationData={successConfetti}
					loop={false}
					style={lottieStyle}
				/>
			</div>
			<div className="h-16"></div>
			<div className="max-w-xs flex flex-col gap-4 px-3 mb-6">
				<h2 className="text-xl text-center font-medium">
					Awesome
				</h2>
				<p className="md:text-lg sm:text-base text-center">
					Your Business account has been created successfully!
				</p>
				<Button onClick={props.onClose} intent="primary">
					Proceed
				</Button>
			</div>
		</Dialog>
	);
};

export default SuccessDialog;
