import slugify from "slugify";

type TruncateOptions = {
	maxLength: number;
	ending?: string;
};

export const truncate = (text: string, options: TruncateOptions): string => {
	const ending = options.ending || "...";

	if (text.length <= options.maxLength) {
		return text;
	}

	return text.substring(0, options.maxLength) + ending;
};

export const toSlug = (text: string): string => {
	if (!text) {
		return "";
	}

	return slugify(text, {
		// eslint-disable-next-line no-useless-escape
		remove: /[*+~.,()'"!:@$#^><[\]\{\}\?;\\/=`]/g,
		replacement: "_"
	});
};

export const isNumeric = (str: any) =>  {
	if (typeof str != "string") return false;
	return !isNaN(parseFloat(str));
};
