export default class User {
	id: string;
	email: string;
	scheduledDeletion?: string;
	emailVerified?: boolean;

	constructor(apiUser: any) {
		this.id = apiUser?.id;
		this.email = apiUser?.description;	
		this.scheduledDeletion = apiUser?.scheduledDeletion;
		this.emailVerified = apiUser?.emailVerified;
	}
}
